<mat-dialog-container>
  <mat-dialog-content>
    <app-user-spinner *ngIf="training == null || user == null || labels == null"></app-user-spinner>
<div class="container">
  <div class='row' *ngIf="data.passedTest">
    <div class = "col-11 col-sm-11" style = "margin: auto; margin-top: 11%; text-align: left;">
      <h1 class="doneLabel" style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: white; font-size: 40px;">{{labels.lblYouPassed}}</h1>
      <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">{{labels.lblSectionCongrats}} <br>
        {{data.section.name}}. <br>
        {{labels.lblYourSuccesRate}} <span [style.color] = "color" style = "font-weight: bold;">{{data.percentage}}%</span><br>
        {{labels.lblYouAnswered}} <span [style.color] = "color" style = "font-weight: bold;">{{data.corrcetAnswersCounter}}</span> {{labels.lblOf}} <span [style.color] = "color" style = "font-weight: bold;">{{data.questions}}</span> {{labels.lblQuestions}}<br>
        {{labels.lblYouHaveWon}}  <span [style.color] = "color" style = "font-weight: bold;">{{data.points}}</span> {{labels.lblOf}} <span [style.color] = "color" style = "font-weight: bold;">{{data.maxPoints}}</span> {{labels.lblPoints}}.
      </h3>
      <div class="row">
        <div class="col-12" *ngIf = "btnContinue" style = "margin: auto;">
          <button class="login-button" (click)="goToTraining()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblContinue}}</h3>
              </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class='row' *ngIf="!data.passedTest">
    <div class = "col-11 col-sm-11" style = "margin: auto; margin-top: 11%; margin-bottom: 7%; text-align: left;">
      <h1 class="highlightOffLabel" style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: white; font-size: 40px;">{{labels.lblYoudDidntPassed}}</h1>
      <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">{{labels.forPassingTheSetionTest}} 
        {{labels.lblMinPoints}} 
        <span [style.color] = "color" style = "font-weight: bold;">{{data.minPercentage}}%.</span><br>
        {{labels.lblYourSuccesRate}} <span [style.color] = "color" style = "font-weight: bold;">{{data.percentage}}%.</span><br>
        {{labels.lblYouAnswered}} <span [style.color] = "color" style = "font-weight: bold;">{{data.corrcetAnswersCounter}}</span> {{labels.lblOf}} <span style="color:rgb(241,106,100);" style = "font-weight: bold;">{{data.questions}}</span> {{labels.lblQuestions}}<br>
        <!-- {{labels.lblYouHaveWon}}  <span [style.color] = "color" style = "font-weight: bold;">{{data.points}}</span> {{labels.lblOf}} <span style="color:rgb(241,106,100);" style = "font-weight: bold;">{{data.maxPoints}}</span> {{labels.lblPoints}} -->
      </h3>
      <h3 *ngIf="data.timeIsUp" [style.color] = "color">{{labels.lblTimeIsUp}}</h3>
      <div class="row">
        <div class="col-12" *ngIf = "btnGoToTraining" style = "margin: auto;">
          <button class="login-button" (click)="goToHomePage()">
              <div class = "col-5" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblContinue}}</h3>
              </div>
          </button>
        </div>
        <div class="col-12" *ngIf = "btnTryAgain" style = "margin: auto;">
          <button class="login-button" (click)="goToTestPage()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblTryAgain}}</h3>
              </div>
          </button>
        </div>
        <div class="col-12" *ngIf = "btnContinue" style = "margin: auto;">
          <button class="login-button" (click)="goToTraining()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblContinue}}</h3>
              </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

  </mat-dialog-content>
</mat-dialog-container>