import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { User } from '../models/user.model'
import { Department } from '../models/department.model'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Training } from './../models/training.model'
import { TrainingSection } from './../models/training-section.model'
import { saveAs } from 'file-saver';
import { LocalStorageService } from './services/localStorage-service/local-storage.service';
import { Test } from '../models/test.model';
import { ControlQuestion } from '../models/controlQuestion.model';
import { ControlQuestionAnswers } from '../models/controlQuestionAnswers.model';
import { Unit } from '../models/unit.model';
import { Trainer } from '../models/trainer.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminLanguageService } from './services/admin-language-service/admin-language.service';
import { TestQuesetion } from './admin-test/admin-test-questions-and-answers/admin-test-questions-and-answers.component';
import { TestQuestion } from '../models/testQuestion.model';
interface Response {
  training: Training
  sections: TrainingSection[]
  units: Unit[]
  questions: ControlQuestion[]
  con_answers: ControlQuestionAnswers[]
}
interface createUserResponse {
  code: string
}
@Injectable({
  providedIn: 'root'
})
export class DashboardService implements OnInit {

  departments$ = new BehaviorSubject(null)
  training$ = new BehaviorSubject(null)
  questions$ = new BehaviorSubject(null)
  users: User[]
  labels;
  labelsSub: Subscription;
  downloading$ = new BehaviorSubject(null)
  sections$ = new BehaviorSubject(null)
  ngOnInit() {
    this.getUsers()
  }
  constructor(public snackBar: MatSnackBar, private http: HttpClient, private localStorageService: LocalStorageService, private adminLanguageService: AdminLanguageService) {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  bigChart() {
    return [{
      name: 'Asia',
      data: [502, 635, 809, 947, 1402, 3634, 5268]
    }, {
      name: 'Africa',
      data: [106, 107, 111, 133, 221, 767, 1766]
    }, {
      name: 'Europe',
      data: [163, 203, 276, 408, 547, 729, 628]
    }, {
      name: 'America',
      data: [18, 31, 54, 156, 339, 818, 1201]
    }, {
      name: 'Oceania',
      data: [2, 2, 2, 6, 13, 30, 46]
    }]
  }

  returnUsers() {
    this.getUsers()
    return this.users
  }
  getUsers() {
    return this.http.get(this.localStorageService.serversIpAddress + 'users')
  }
  getUsersNoPending() {
    return this.http.get(this.localStorageService.serversIpAddress + 'users/noPending')
  }
  getUser(id: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/${id}`)
  }
  getUsersInDep(depId: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/department/${depId}`)
  }
  getTrainings() {
    return this.http.get(this.localStorageService.serversIpAddress + 'trainings')
  }
  getTrainingsAndEvents() {
    return this.http.get(this.localStorageService.serversIpAddress + 'trainings/trainings-and-events')
  }
  getTrainings2() {
    return this.http.get(this.localStorageService.serversIpAddress + 'trainings/trainings-all')
  }
  getTrainingsForTest(id: string, type: number) {
    return this.http.get(this.localStorageService.serversIpAddress + `trainings/test/${id}/${type}`)
  }
  getTrainingsNotForTest(id: string, type: number): Observable<Training[]> {
    return this.http.get<Training[]>(this.localStorageService.serversIpAddress + `trainings/testNotForTraining/${id}/${type}`)
  }
  fetchUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.localStorageService.serversIpAddress + 'users')
  }
  deleteUser(id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `users/delete/${id}`).subscribe()
  }
  deleteAdmin(id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `administrators/delete/${id}`).subscribe()
  }
  deleteTrainer(id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `users/trainers/delete/${id}`).subscribe()
  }
  fetchDepartments(headAdmin: boolean, adminId: string, depId: string): Observable<Department[]> {
    this.http.get<Department[]>(this.localStorageService.serversIpAddress + 'departments').subscribe((departments) => {
      if (headAdmin) {
        departments.forEach(d => {
          d.children = []
          d.children = departments.filter((department) => department.parentId == d.id)
          d.show = false
        })
        let deps = departments.filter((department) => department.parentId == "")
        console.log(deps)
        this.departments$.next(deps)
        return deps
      }
      else {
        departments.forEach(d => {
          d.children = []
          d.children = departments.filter((department) => department.parentId == d.id)
          d.show = false
        })
        let deps = departments.filter((department) => department.adminId == adminId)
        console.log(deps)
        this.departments$.next(deps)
        return deps
      }

    })

    return this.departments$
  }
  fetchAllDepartments(): Observable<Department[]> {
    return this.http.get<Department[]>(this.localStorageService.serversIpAddress + 'departments')
  }
  fetchAdminsDepartments(depId: string): Observable<Department[]> {
    return this.http.get<Department[]>(this.localStorageService.serversIpAddress + `departments/admin/${depId}`)
  }
  fetchAvailableParents(depId: string): Observable<Department[]> {
    return this.http.get<Department[]>(this.localStorageService.serversIpAddress + `departments/parentAvailable/${depId}`)
  }
  fetchAvailableParentsNotHeadAdmin(adminDepId: string, depId: string): Observable<Department[]> {
    if (adminDepId == depId) {
      return this.http.get<Department[]>(this.localStorageService.serversIpAddress + `departments/parentAvailable/${depId}`)
    }
    else {
      return this.http.get<Department[]>(this.localStorageService.serversIpAddress + `departments/parentAvailable/notHeadAdmin/${adminDepId}/${depId}`)
    }
  }
  fetchPositions() {
    return this.http.get(this.localStorageService.serversIpAddress + 'positions')
  }
  editUser(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/update', data)
  }
  editAdmin(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'administrators/update', data)
  }
  editTrainer(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/trainers/update', data)
  }

  insertUser(data): Observable<createUserResponse> {
    return this.http.post<createUserResponse>(this.localStorageService.serversIpAddress + 'users/create', data)
  }
  insertAdmin(data): Observable<createUserResponse> {
    return this.http.post<createUserResponse>(this.localStorageService.serversIpAddress + 'administrators/create', data)
  }

  insertPendingUser(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/insert-pending-user', data)
  }

  insertTrainer(data): Observable<createUserResponse> {
    return this.http.post<createUserResponse>(this.localStorageService.serversIpAddress + 'users/trainers/create', data)
  }

  fetchTrainigs(): Observable<Training[]> {
    return this.http.get<Training[]>(this.localStorageService.serversIpAddress + 'trainings')
  }

  updateTrainings({ id, fromDate, toDate, trainings }) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/update-trainings', { id, fromDate, toDate, trainings })
  }

  updateTraining(data) {
    console.log('uipdate trainings')
    return this.http.post(this.localStorageService.serversIpAddress + 'trainings/update-name-description', data)
  }

  deleteTraining(id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `trainings/delete/${id}`)
  }
  deleteTestForTraining(id: string, type: number, testId:string) {
    return this.http.delete(this.localStorageService.serversIpAddress + `trainings/test/delete/${id}/${type}/${testId}`)
  }

  addNewTraining(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'trainings/create', data)
  }
  copyTraining(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'trainings/copy-training', data)
  }
  fetchSectionsForTraining(id): Observable<TrainingSection[]> {
    return this.http.get<TrainingSection[]>(this.localStorageService.serversIpAddress + `training-sections/sections/${id}`)
  }
  getSectionsForTraining(id): Observable<TrainingSection[]> {
    this.http.get(this.localStorageService.serversIpAddress + `training-sections/sections/${id}`).subscribe(response => {
      this.sections$.next(response)
      return response
    })
    return this.sections$
  }

  getUnitsForSection(id, sectionId) {
    return this.http.get(this.localStorageService.serversIpAddress + `units/units-for-section/${id}/${sectionId}`)
  }

  getSectionName(id, sectionId) {
    return this.http.get(this.localStorageService.serversIpAddress + `trainings/${id}/${sectionId}`)
  }

  editAdminInfo(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'administrators/edit-admin-info', data)
  }
  editAdminInfo1(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'administrators/edit-admin-info', data)
  }

  insertSection(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'training-sections/create', data)
  }

  deleteSection(id, trainingId) {
    return this.http.delete(this.localStorageService.serversIpAddress + `training-sections/delete/${id}/${trainingId}`)
  }

  editSection(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'training-sections/update-name', data)
  }

  insertUnit(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/create', data)
  }
  deleteUnit(id, trainingSectionId, trainingId) {
    return this.http.delete(this.localStorageService.serversIpAddress + `units/delete/${id}/${trainingSectionId}/${trainingId}`)
  }
  deleteControlAns(trainingId, sectionId, unitId, questionId, id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `units/controlquestion/delete-answer/${trainingId}/${sectionId}/${unitId}/${questionId}/${id}`)
  }
  deleteControlQuestion(trainingId, sectionId, id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `units/controlquestion/delete-question/${trainingId}/${sectionId}/${id}`)
  }
  editUnit(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/edit', data)
  }
  insertDepartment(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'departments/create', data)
  }
  deleteDepartment(id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `departments/delete/${id}`)
  }
  editDepartment(id, data) {
    return this.http.post(this.localStorageService.serversIpAddress + `departments/update/${id}`, data)
  }
  changeTrainingSectionsOrder(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'training-sections/change-order', data)
  }
  fetchTests(): Observable<Test[]> {
    return this.http.get<Test[]>(this.localStorageService.serversIpAddress + 'tests')
  }
  addTest(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'tests/create', data)
  }
  changePassword(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'administrators/admin-change-password', data)
  }
  resetPassword(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'administrators/login/reset-password', data)
  }
  editTest(data) {
    return this.http.post(this.localStorageService.serversIpAddress + `tests/update/${data.id}`, data)
  }
  deleteTest(id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `tests/delete/${id}`)
  }
  changeSectionUnitOrder(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/change-order', data)
  }

  fetchQuestionsForTest(id): Observable<TestQuestion[]> {
    this.http.get<TestQuesetion[]>(this.localStorageService.serversIpAddress + `test-questions/${id}`).subscribe(response => {
      console.log(response)
      this.questions$.next(response)
      return response
    })
    return this.questions$
  }

  isTrainingAssigned(trainingId) {
    return this.http.get(this.localStorageService.serversIpAddress + `trainings/assigned/${trainingId}`)
  }

  isTestAssigned(testId) {
    return this.http.get(this.localStorageService.serversIpAddress + `tests/assigned/${testId}`)
  }

  insertQuestionForTest(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-questions/create', data)
  }

  insertControlQuestion(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/controlquestion/create', data)
  }
  insertControlQuestionAnswer(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/controlquestion/createAnswer', data)
  }

  editQuestionForTest(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-questions/update', data)
  }
  deleteQuestionForTest(testId, id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `test-questions/delete/${testId}/${id}`)
  }
  fetchAnswersForQuestion(testId, questionId) {
    return this.http.get(this.localStorageService.serversIpAddress + `test-question-answers/answers-for-question/${testId}/${questionId}`)
  }
  fetchAnswersForControlQuestion(trainingId, sectionId, unitId) {
    return this.http.get(this.localStorageService.serversIpAddress + `units/controlquestion/control-question-answers/${trainingId}/${sectionId}/${unitId}`)
  }
  fetchControlQuestion(trainingId, sectionId, unitId) {
    return this.http.get(this.localStorageService.serversIpAddress + `units/controlquestion/${trainingId}/${sectionId}/${unitId}`)
  }
  fetchQuestion(testId, questionId) {
    return this.http.get(this.localStorageService.serversIpAddress + `test-questions/${testId}/${questionId}`)
  }
  changeAnswerStatus(data) {
    return this.http.post(this.localStorageService.serversIpAddress + `test-question-answers/change-answer-status`, data)
  }

  changeControlAnswerStatus(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/controlquestion/change-control-answer-status', data)
  }

  creteAnswer(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-question-answers/create', data)
  }
  updateTextOfAnswer(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-question-answers/update-name', data)
  }
  updateTextOfControlAnswer(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/controlquestion/update-answer', data)
  }
  updateTextOfControlQuestion(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/controlquestion/update-question', data)
  }
  deleteAnswer(testId, questionId, id) {
    return this.http.delete(this.localStorageService.serversIpAddress + `test-question-answers/delete/${testId}/${questionId}/${id}`)
  }
  changeOrderOfQuestions(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-questions/change-order', data)
  }
  setQuestionsOrder(order: boolean, testId: string) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-questions/random', { order: order, testId: testId })
  }
  changeAswersOrder(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-question-answers/change-order', data)
  }
  changeControlQuestionAnswersOrder(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/controlQuestion/change-order', data)
  }
  setAnswersOrder(order: boolean, testId: string, questionId: string) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-question-answers/random', { order: order, testId: testId, questionId: questionId })
  }
  setControlQuestionAnswersOrder(order: boolean, trainingId: string, sectionId: string, unitId: string, questionId: string) {
    return this.http.post(this.localStorageService.serversIpAddress + 'units/controlQuestion/random', { order: order, trainingId: trainingId, sectionId: sectionId, unitId: unitId, questionId: questionId })
  }
  changeOrderOfAnswers(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'test-question-answers/change-order', data)
  }
  fetchTestsByType(type) {
    return this.http.get(this.localStorageService.serversIpAddress + `tests/tests-by-type/${type}`)
  }
  assignTests(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'trainings/assign-tests', data)
  }
  assignTest(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'trainings/assign-test', data)
  }
  assignTestToTraining(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'trainings/assign-test-to-training', data)
  }
  fetchTrainingsForUser(id): Observable<Training[]> {
    return this.http.get<Training[]>(this.localStorageService.serversIpAddress + `users/trainings-for-user/${id}`)
  }
  updateTrainingsForUser(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/update-trainings', data)
  }
  fetchUsersPerDeps(id) {
    return this.http.get(this.localStorageService.serversIpAddress + `departments/users-in-department/${id}`)
  }
  fetchUsersPerAdminDeps(id) {
    return this.http.get(this.localStorageService.serversIpAddress + `departments/admin/users-in-department/${id}`)
  }
  fetchUsersPerTrainings(id) {
    return this.http.get(this.localStorageService.serversIpAddress + `departments/users-in-training/${id}`)
  }
  fetchUsersPerTrainingsAdmin(id, companyId) {
    return this.http.get(this.localStorageService.serversIpAddress + `departments/admin/users-in-training/${id}/${companyId}`)
  }
  fetchTrainingStatus() {
    return this.http.get(this.localStorageService.serversIpAddress + 'users/training-finished')
  }
  trainingStatus(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/training-status', data)
  }
  oneTrainingStatus(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/one-training-status', data)
  }
  trainingStatusForAdminUsers(depId) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/admin/training-status/${depId}`)
  }
  oneTrainingStatusForAdminUsers(depId, trainingId) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/admin/one-training-status/${depId}/${trainingId}`)
  }
  fetchTrainingSuccess(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/successful-trainings', data)
  }

  trainingSuccess(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/training-success', data)
  }
  trainingSuccessForAdminUsers(depId) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/admin/training-success/${depId}`)
  }
  generatReportAdmin(id, type, lang, headAdmin, departmentId, isZoomTraining) {
    this.http.get(this.localStorageService.serversIpAddress + `units/adminGeneralReport/${id}/${type}/${lang}/${headAdmin}/${departmentId}/ ${isZoomTraining}`, { responseType: "blob" }).toPromise()
      .then(blob => {
        saveAs(blob, "adminReport.pdf");
      })
      .catch(err => console.error("download error = ", err))
  }
  generateExcelReport(id, type, lang, headAdmin, departmentId) {
    return this.http.get(this.localStorageService.serversIpAddress + `units/excelReport/${id}/${type}/${lang}/${headAdmin}/${departmentId}`, { responseType: "blob" }).toPromise()
      .then(blob => {
        saveAs(blob, "adminReport.xlsx");
        this.downloading$.next(false)
      })
      .catch(err => console.error("download error = ", err))
  }
  generateReportForUsers(id, type, fromDate, toDate, users, lang, isZoomTraining) {

    for (let index = 0; index < users.length; index++) {
      setTimeout(() => {

        this.http.get(this.localStorageService.serversIpAddress + `units/adminReportUsers/${id}/${type}/${fromDate}/${toDate}/${users[index]}/${lang}/${isZoomTraining}`, { responseType: "blob" }).toPromise()
          .then(blob1 => {
            console.log(blob1)
            if (blob1.size < 10) {
              console.log(blob1)
              this.snackBar.open(this.labels.lblCantGenerate, 'OK', {
                verticalPosition: 'top',
                horizontalPosition: 'end',
                duration: 5000
              })
            }
            else {
              saveAs(blob1, "usersReport.pdf");
            }

          })
          .catch(err => {
            console.error("download error = ", err)
            this.snackBar.open(this.labels.lblCantGenerate, 'OK', {
              verticalPosition: 'top',
              horizontalPosition: 'end',
              duration: 5000
            })
          })
      }, 500);

    }

  }
  generateReportForSectors(id, type, fromDate, toDate, sectorId, lang, isZoomTraining ) {
    this.http.get(this.localStorageService.serversIpAddress + `units/adminReportSectors/${id}/${type}/${fromDate}/${toDate}/${sectorId}/${lang}/${isZoomTraining}`, { responseType: "blob" }).toPromise()
      .then(blob => {
        console.log("BROB SIZE: ", blob.size)
        if (blob.size < 10) {
          console.log(blob)
          this.snackBar.open(this.labels.lblCantGenerate, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
        }
        else {
          saveAs(blob, "adminReport.pdf");
        }
        
      })
      .catch(err => {
        console.error("download error = ", err)
        this.snackBar.open(this.labels.lblCantGenerate, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      })

  }

  getall(id) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/all/${id}`)
  }

  getTraining(id) {
    return this.http.get(this.localStorageService.serversIpAddress + `trainings/training/${id}`)
  }

  getTrainers() {
    return this.http.get(this.localStorageService.serversIpAddress + 'users/trainers')
  }

  getDepartmentById(id: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `departments/${id}`)
  }

  fetchAdministrators() {
    return this.http.get(this.localStorageService.serversIpAddress + "administrators/")
  }

  getUsersTrainings(id: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/userTrainings/${id}`)
  }
  getUsersForTrainings(id: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/usersForTraining/${id}`)
  }
  getAdminsUsersForTrainings(id: string, trainingId: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/department/training/${id}/${trainingId}`)
  }
  getAdminsUsersNotForTrainings(id: string, trainingId: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/department/notForTraining/${id}/${trainingId}`)
  }
  getUsersNotForTrainings(id: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/usersNotForTraining/${id}`)
  }

  deleteUserXtrainings(id: string, trainingId: string) {
    return this.http.delete(this.localStorageService.serversIpAddress + `users/userTrainings/delete/${id}/${trainingId}`)
  }
  deleteUserXTrainingTest(id: string, trainingId: string) {
    return this.http.delete(this.localStorageService.serversIpAddress + `users/userTrainings/test/delete/${id}/${trainingId}`)
  }

  getTrainingWithSections(id: string): Observable<Training> {2
    this.http.get<Response>(this.localStorageService.serversIpAddress + `trainings/${id}`).subscribe(
      response => {
        let training = response.training
        let sections = response.sections;
        let control_questions = response.questions;

        control_questions.forEach((q) => {
          q.answers = response.con_answers.filter((answer) => answer.questionId == q.id)
        })

        sections.forEach((s) => {
          let trUnits = response.units.filter((unit) => unit.trainingSectionId == s.id)
          let trainingUnits = []
          trainingUnits = trUnits.sort((a, b) => (a.order < b.order ? -1 : 1));
          s.units = trainingUnits
        })

        let trSections = []
        trSections = sections.sort((a, b) => (a.order < b.order ? -1 : 1));
        training.sections = trSections;
        console.log(training)
        this.training$.next(training)
        return training
      }
    )
    return this.training$
  }

  getTrainer(id: string): Observable<Trainer> {
    return this.http.get<Trainer>(this.localStorageService.serversIpAddress + `users/trainer/${id}`)
  }

  getTest(id: string): Observable<Test> {
    return this.http.get<Test>(this.localStorageService.serversIpAddress + `tests/${id}`)
  }

  fetchTrainigsForSector(): Observable<Training[]> {
    return this.http.get<Training[]>(this.localStorageService.serversIpAddress + 'trainings/trainings-for-sector')
  }

  updateSectorTrainings({ id, trainings }) {
    return this.http.post(this.localStorageService.serversIpAddress + 'departments/update-trainings', { id, trainings })
  }

  insertUsersFromExcel(users, lang) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/excel', { users, lang })
  }

  getTrainersTrainings(id: string): Observable<Training[]> {
    return this.http.get<Training[]>(this.localStorageService.serversIpAddress + `users/trainings-for-trainer/${id}`)
  }

  fetchTrainer(id: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/trainer/${id}`)
  }

  getUnitName(id, sectionId, unitId) {
    return this.http.get(this.localStorageService.serversIpAddress + `trainings/${id}/${sectionId}/${unitId}`)
  }

  addDocumentForUser(data, userId) {
    console.log(data)
    console.log(userId)
    return this.http.post(this.localStorageService.serversIpAddress + 'users/documents/create', { data, userId })
  }

  fetchUsersDocuments(userId) {
    return this.http.get(this.localStorageService.serversIpAddress + `users/documents/${userId}`)
  }

  deleteUsersDocument(userId, docId) {
    console.log(userId)
    console.log(docId)
    return this.http.delete(this.localStorageService.serversIpAddress + `users/documents/delete/${userId}/${docId}`)
  }

  insertTermsOfUse(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'administrators/terms-of-use', data)
  }

  getSectionForTest(id, trainingId) {
    return this.http.get(this.localStorageService.serversIpAddress + `training-sections/section/${id}/${trainingId}`)
  }

  assignTestForSection(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'training-sections/assign-test', data)
  }

  assignTestForZoomTraining(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'administrators/assign-test-zoom', data)
  }

  getSectionsForTest(testId: string) {
    return this.http.get(this.localStorageService.serversIpAddress + `training-sections/sections-for-test/${testId}`)
  }

  deleteTestForSection(section:TrainingSection){
    return this.http.post(this.localStorageService.serversIpAddress + 'training-sections/test/delete', section)
  }

  addEvent(data){
    return this.http.post(this.localStorageService.serversIpAddress + `administrators/addCalendarEvent`, data)
  }
  addMeeting(data){
    return this.http.post(this.localStorageService.serversIpAddress + `administrators/add-zoom-meeting`, data)
  }

  addChatMeeting(data){
    return this.http.post(this.localStorageService.serversIpAddress + `administrators/add-zoom-chat-meeting`, data)
  }

  zoomAccessToken(data){
    console.log(data)
    return this.http.post(this.localStorageService.serversIpAddress + `administrators/zoom-token`, data)
  }

  getZoomUserDetails(accessToken){
    return this.http.get(this.localStorageService.serversIpAddress + `administrators/zoom-users-details/${accessToken}`)
  }
  deleteZoomMeeting(meetingId){
    return this.http.delete(this.localStorageService.serversIpAddress + `administrators/delete-zoom-meeting/${meetingId}`)
  }
}