import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http'
import { DashboardService } from './../../dashboard.service'
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Department } from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { AngularFireStorage } from '@angular/fire/storage'
import { Trainer } from 'src/app/models/trainer.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCropperDialogComponent } from "../../image-cropper-dialog/image-cropper-dialog.component"
import { ImageTrainingCropperDialogComponent } from '../../image-training-cropper-dialog/image-training-cropper-dialog.component';

@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {

  nameControl = new FormControl('', [Validators.required]);
  desControl = new FormControl('', [Validators.required]);
  err: boolean = false;
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  @ViewChild('imageUpload2') imageUpload2: ElementRef

  labels;
  labelsSub: Subscription;
  selectedImage: File = null
  selectedImage2: File = null
  downloadUrl$: Observable<string>
  downloadUrlSub: Subscription
  trainers: Trainer[]
  duration: Number = 0
  uploading: boolean = false
  isTestOnly: boolean = false;
  imgUrl: string = ""
  imgUrl2: string = ""
  constructor(private router: Router, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<InsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService, public adminLanguageService: AdminLanguageService, private storage: AngularFireStorage, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.err = false;
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.getTrainers()
  }

   async onSubmit() {
    if (this.insertForm.value.name == "" || this.insertForm.value.description == "" || this.insertForm.value.trainerId == "" || this.insertForm.value.priority == "" || this.selectedImage == null || this.selectedImage2 == null) {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      
      console.log(this.insertForm.value)
      let data = {
        name: this.insertForm.value.name,
        description: this.insertForm.value.description,
        imagePath: null,
        trngInfoImagePath: null,
        duration: this.insertForm.value.duration,
        trainerId: this.insertForm.value.trainerId,
        priority: this.insertForm.value.priority,
        isTestOnly: this.isTestOnly,
        dateCreated: new Date()
      }
      if(this.selectedImage.name == this.selectedImage2.name){
        this.err = true;
        this.snackBar.open(this.labels.lblImageNameError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      } else {
        this.uploading = true
        console.log("IMAGE 1: ", this.selectedImage)
        console.log("IMAGE 2: ", this.selectedImage2)
          let ref = this.storage.ref(this.selectedImage.name)
          ref.put(this.selectedImage).then(t => {
            this.downloadUrlSub = ref.getDownloadURL().subscribe(url => {
              data.imagePath = url
              this.getUrl(url)
            })
          })

          let ref2 = this.storage.ref(this.selectedImage2.name)
          ref2.put(this.selectedImage2).then(t => {
            this.downloadUrlSub = ref2.getDownloadURL().subscribe(url => {
              data.trngInfoImagePath = url
              this.getUrl2(url)
            })
          })

          data.imagePath = this.imgUrl
          data.trngInfoImagePath = this.imgUrl2
          
          setTimeout(() => {
            console.log("DATA:::", data);
            console.log(this.imgUrl)
            console.log(this.imgUrl2)
            this.dashboardService.addNewTraining(data).subscribe((response) => {
              this.dialogRef.close()
              const url = `/admin/training-details/${response}`;
              this.router.navigateByUrl(url);
            })
          }, 2500);
        }
    }
  }

  getUrl(url){
    console.log("UUURRRLLL: ", url)
    this.imgUrl = url
  }

  getUrl2(url){
    console.log("UUURRRLLL111: ", url)
    this.imgUrl2 = url
  }

  onImageChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "jpg" || ext == "jpeg" || ext == "png") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number: number = +iConvert
      if (number <= 5) {
        this.selectedImage = event.target.files[0]
        const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
          width: "500px",
          height: "600px",
          data: event
        })

        dialogRef.afterClosed().subscribe((result) => {
          this.selectedImage = result
          console.log(this.selectedImage)
        })
      }
      else {
        this.snackBar.open(this.labels.lblMaxFileSizeImage, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }
    }
    else {
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload.nativeElement.value = ""
    }
  }

  onImageChanged2(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "jpg" || ext == "jpeg" || ext == "png") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number: number = +iConvert
      if (number <= 5) {
        this.selectedImage2 = event.target.files[0]
        const dialogRef = this.dialog.open(ImageTrainingCropperDialogComponent, {
          width: "500px",
          height: "600px",
          data: event
        })

        dialogRef.afterClosed().subscribe((result) => {
          this.selectedImage2 = result

          console.log(this.selectedImage2)
        })
      }
      else {
        this.snackBar.open(this.labels.lblMaxFileSizeImage, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload2.nativeElement.value = ""
      }
    }
    else {
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload2.nativeElement.value = ""
    }
  }

  onNoClick() {
    this.dialogRef.close()
  }

  getTrainers() {
    this.dashboardService.getTrainers().subscribe((trainers) => {
      this.trainers = trainers as Trainer[]
      console.log(trainers)
    })
  }

  updateImage() {
    this.selectedImage = null
  }

  updateImage2() {
    this.selectedImage2 = null
  }

  toggleIsTestOnly() {
    this.isTestOnly = !this.isTestOnly;
  }

}