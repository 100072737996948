import { Position } from '@angular/compiler';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Department } from 'src/app/models/department.model';
import { Training } from 'src/app/models/training.model';
import { User } from 'src/app/models/user.model';
import { DashboardService } from '../dashboard.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { EditDialogComponent } from '../admin-user/edit-dialog/edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserTrainingsDialogComponent } from '../admin-user/user-trainings-dialog/user-trainings-dialog.component';
import { DeleteTrainingDialogComponent } from './delete-training-dialog/delete-training-dialog.component'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AdminUploadDocumentComponent } from '../admin-upload-document/admin-upload-document.component';
import { UserDocument } from 'src/app/models/userDocument.model'
import { DeleteDocumentDialogComponent } from './delete-document-dialog/delete-document-dialog.component';

@Component({
  selector: 'app-admin-user-profile',
  templateUrl: './admin-user-profile.component.html',
  styleUrls: ['./admin-user-profile.component.scss']
})
export class AdminUserProfileComponent implements OnInit {
  displayedColumns: string[] = ['name', 'description', 'actions'];
  displayedColumns1: string[] = ['name', 'actions'];
  departments: Department[]
  positions: Position[]
  t
  labels;
  labelsSub: Subscription;
  err: boolean = false
  id: string
  data: User
  dataSource
  dataSource1
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private router: ActivatedRoute, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService, public dialog: MatDialog,) { }
  @ViewChild('editForm') editForm: NgForm

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      this.id = params['id']
      this.fetcDepartments();
      this.fetchPositions()
      this.fetchUser()
      this.getTrainings()
      this.fetchUsersDocuments()
    })
    this.err = false;
    this.getLang()
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

  public doFilter1 = (value: string) => {
    this.dataSource1.filter = value
  }

  getTrainings() {
    this.dashboardService.getUsersTrainings(this.id).subscribe(trainings => {
      let trngs = trainings as Training[]
      this.dataSource = new MatTableDataSource(trngs)
      this.t = trainings
      setTimeout(() => {
        this.dataSource.sort = this.sort.toArray()[0]
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.filterPredicate = (data: Training, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
      }, 1000);
    })
  }
  onSubmit() {
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == "" || this.editForm.value.departmentId == "" || this.editForm.value.email == "") {
      // this.errorClass = "errorClass"
      this.err = true
    } else {
      this.editUser(this.editForm.value)
      console.log(this.editForm.value)
    }
  }
  onNoClick(): void {
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  fetcDepartments() {
    return this.dashboardService.fetchAllDepartments().subscribe((response) => {
      setTimeout(() => {
        this.departments = response as Department[]
      }, 1000);
    })
  }
  fetchPositions() {
    return this.dashboardService.fetchPositions().subscribe((response) => {
      this.positions = response as Position[]

    })
  }
  fetchUser() {
    return this.dashboardService.getUser(this.id).subscribe((response) => {
      this.data = response as User
      console.log(this.data)
    })
  }

  fetchUsersDocuments() {
    return this.dashboardService.fetchUsersDocuments(this.id).subscribe((response) => {
      let docs = response as UserDocument[]
      this.dataSource1 = new MatTableDataSource(docs)
      setTimeout(() => {
        this.dataSource1.sort = this.sort.toArray()[1]
        this.dataSource1.paginator = this.paginator.toArray()[1]
        this.dataSource1.filterPredicate = (data: Training, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
      }, 1000);
      
    })
  }

  editUser(data) {
    this.dashboardService.editUser(data).subscribe((response) => {
      console.log(response)
    })
  }

  openEditDialog(user) {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '500px',
      data: user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchUser()
    });
  }

  openUserTrainingsDialog(user) {
    const dialogRef = this.dialog.open(UserTrainingsDialogComponent, {
      width: '500px',
      data: user
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.dataSource1 = null
      this.getTrainings()
      this.fetchUsersDocuments()
    });
  }

  openDeleteDialog(training, user) {
    const dialogRef = this.dialog.open(DeleteTrainingDialogComponent, {
      width: "500px",
      data: { training: training, user: user }
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.t = null
      this.dataSource1 = null
      this.getTrainings()
      this.fetchUsersDocuments()
    })
  }

  // TODO: add reload test for user
  // openRestartDialog(training, user) {
  //   const dialogRef = this.dialog.open(DeleteTrainingDialogComponent, {
  //     width: "500px",
  //     data: { training: training, user: user }
  //   })
  //   dialogRef.afterClosed().subscribe((result) => {
  //     this.dataSource = null
  //     this.t = null
  //     this.dataSource1 = null
  //     this.getTrainings()
  //     this.fetchUsersDocuments()
  //   })
  // }

  openDocumentDialog() {
    const dialogRef = this.dialog.open(AdminUploadDocumentComponent, {
      width: '400px',
      data: { userId: this.data.id }

    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.dataSource1 = null
      this.getTrainings()
      this.fetchUsersDocuments()
    });
  }

  openDocumentDeleteDialog(document, user) {
    const dialogRef = this.dialog.open(DeleteDocumentDialogComponent, {
      width: "500px",
      data: { document: document, user: user }
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.dataSource1 = null
      this.getTrainings()
      this.fetchUsersDocuments()
    })
  }





}