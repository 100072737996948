<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblAddTest}}</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #assignForm="ngForm">
    <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblTest}}</mat-label>
            <mat-select [ngModel]="data.testId" name='test'>
                <mat-option *ngFor='let test of tests' value="{{test.id}}">{{test.name}}</mat-option>
            </mat-select>
            <br />
            <button mat-icon-button matSuffix *ngIf="data.testId" (click)="clearTestSelection($event)">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblPretest}}</mat-label>
            <mat-select  [ngModel]="data.pretestId" name='pretest'>
                <mat-option *ngFor='let pretest of preTests' value="{{pretest.id}}">{{pretest.name}}</mat-option>
            </mat-select>
            <br />
            <button mat-icon-button matSuffix *ngIf="data.pretestId" (click)="clearPreTestSelection($event)">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblSurvey}}</mat-label>
            <mat-select [ngModel]="data.surveyId" name='survey'>
                <mat-option *ngFor='let survey of surveys' value="{{survey.id}}">{{survey.name}}</mat-option>
            </mat-select>
            <br />
            <button mat-icon-button matSuffix *ngIf="data.surveyId" (click)="clearSurveySelection($event)">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <div mat-dialog-actions>
            <!-- <button class="noBtn" mat-button (click)="onNoClick()">{{labels.lblNo}}</button> -->
            <button class="btn" cdkFocusInitial type="submit" >{{labels.lblAdd}}</button>
        </div>
    </div>
  </form>
</div>