import {Component, NgModule, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {GenerateReportsComponent} from '../generate-reports/generate-reports.component';
import {Subscription} from 'rxjs';
import {UserLanguageService} from '../services/user-language-service/user-language.service';
import {Router} from '@angular/router';
import {UserDashboardService} from '../user-dashboard.service';
import {AuthService} from '../services/auth-service/auth.service';




@Component({
  selector: 'global-trainings',
  templateUrl: './global-trainings.component.html',
  styleUrls: ['./global-trainings.component.scss']
})
export class GlobalTrainingsComponent implements OnInit {
  lang
  langSub: Subscription;

  labels;
  labelsSub: Subscription;

  constructor(private languageService: UserLanguageService, private router: Router, private userDashboarService: UserDashboardService, private authService: AuthService) {
  }
  ngOnInit(): void {
    this.langSub = this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    })

    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
}
