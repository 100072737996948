<mat-drawer-container>
  <mat-drawer-content>
    <!-- NASLOV -->
    <div class="row" style="margin: auto; padding: 25px;">
      <div class="d-none d-sm-block col-sm-3"></div>
      <div class="col-1">
        <img routerLink="/user/dashboard" style="cursor: pointer"
             src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FbackIcon.png?alt=media&token=d3776f59-033a-4d06-b5f3-ba9e925bc495"
             alt="faq page image">
      </div>
      <div class="col-3 col-sm-4" style="margin: auto; text-align: center">
        <h3 class="naslov"
            style="color: #0E0D35;font-family: Lato, 'Alegreya Sans', 'Alegreya Sans'; font-size: 30px; font-weight: bold;">
          Додатна упутства за сертификоване геодетске стручњаке</h3>
      </div>
      <div class="col-9 col-sm-3" style="text-align: right;">
        <!-- <span style = "cursor: pointer; padding-right: 15px;" routerLink = "/user/calendar">
          <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FcalendarIcon.png?alt=media&token=0dcbbd8e-d153-4851-af32-658f01446fbd">
        </span> -->

        <span style="cursor: pointer; vertical-align: middle; margin-right: 10px;" routerLink="/user/faq">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webImgs%2FhomeIcon1.png?alt=media&token=f55962fc-39ce-44f5-a7f2-139e4e71c0ea"
            alt="faq icon">
        </span>

        <button (click)="logout()"
                style="background-color: #f1f1f1; vertical-align: middle; font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-size: 16px; padding: 7px; border: 1px solid #f1f1f1; border-radius: 8px;">
          <mat-icon style="vertical-align: middle; color: #0E0D35;">exit_to_app</mat-icon>
          <span class="mobHidden">{{ labels.lblLogOut }}</span>
        </button>
      </div>
    </div>
    <div class="coverImageWrap">
      <img class="coverImage" style="width: 100%;"
           src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Naslovna-fotka.png?alt=media&token=0efd5995-03be-43a0-8fb5-5990c9c4c427">
    </div>
    <div class="custom-accordion">
      <div class="card">
        <h1>Додатна упутства за сертификоване геодетске стручњаке</h1>

        <details class="info">
          <summary>Упутство за
            инсталацију Модула за геодетске организације
          </summary>
          <div class="accordion-item">
            <span>Упутство за инсталацију Модула за геодетске организације</span>
            <a class="download_button"
               href="/assets/Uputstvo1/Uputstvo%20za%20instalaciju%20MGO_v.2.pdf" download><span>Преузми</span><span>PDF</span></a>
          </div>
          <div class="accordion-item">
            <span>Инсталациони прилог "cacerts"</span>
            <a class="download_button"
               href="/assets/Uputstvo1/cacerts.rar" download><span>Преузми</span><span>rar</span></a>
          </div>
        </details>

        <details class="info">
          <summary>Упутство за рад у Модулу за геодетске организације (МГО)</summary>
          <div class="accordion-item">
            <span>Упутство за рад у Едитору МГО-а са предметима из ДQI-а</span>
            <a class="download_button"
               href="/assets/Uputstvo2/Upustvo%20za%20rad%20u%20Editoru%20MGO-a%20sa%20predmetima%20iz%20DQI-a.pdf"
               download><span>Преузми</span><span>PDF</span></a>
          </div>

          <div class="accordion-item">
            <span>Упутство за рад у Едитору МГО-а са предметима из ИСКН-а </span>
            <a class="download_button"
               href="/assets/Uputstvo2/Upustvo%20za%20rad%20u%20Editoru%20MGO-a%20sa%20predmetima%20iz%20ISKN-a.pdf"
               download><span>Преузми</span><span>PDF</span></a>
          </div>
          <div class="accordion-item">
            <span>Упутство МГО ажурирано 27092024 </span>
            <a class="download_button" href="/assets/Uputstvo2/Uputstvo%20MGO_ažurirano%2027092024.pdf" download><span>Преузми</span><span>PDF</span></a>
          </div>
          <div class="accordion-item">
            <span>Упутство МГО УВОД </span>
            <a class="download_button" href="/assets/Uputstvo2/Uputstvo%20MGO_UVOD.pdf"
               download><span>Преузми</span><span>PDF</span></a>
          </div>
          <div class="accordion-item">
            <span>Упутство за нумерацију граничких тачака у МГО-у</span>
            <a class="download_button"
               href="/assets/Uputstvo2/Uputstvo%20za%20numeraciju%20graničkih%20tačaka%20u%20MGO-u.pdf" download><span>Преузми</span><span>PDF</span></a>
          </div>
          <div class="accordion-item">
            <span>Упутство за креирање CSV fajl-a (koordinate)</span>
            <a class="download_button"
               href="/assets/Uputstvo2/Uputstvo%20za%20kreiranje%20CSV%20fajl-a%20(koordinate).pdf" download><span>Преузми</span><span>PDF</span></a>
          </div>
          <div class="accordion-item">
            <span>Template CSV-a </span>
            <a class="download_button"
               href="/assets/Uputstvo2/Template%20csv-a%20(koordinate).csv" download><span>Преузми</span><span>PDF</span></a>
          </div>
        </details>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
