import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserDashboardService } from '../user-dashboard.service';
import * as fs from "../../../../node_modules/file-saver";
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { UserTrainingsDialogComponent } from '../admin-user/user-trainings-dialog/user-trainings-dialog.component';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { Trainer } from 'src/app/models/trainer.model';
import { share } from 'rxjs/operators';
import { UserDocument } from 'src/app/models/userDocument.model';

@Component({
  selector: 'app-user-cards',
  templateUrl: './user-cards.component.html',
  styleUrls: ['./user-cards.component.scss'],
  host: { '(click)': 'onClick()'}
})
export class UserCardsComponent implements OnInit {

  user: User
  userSub: Subscription;

  labels;
  labelsSub: Subscription;

  lang
  langSub: Subscription;

  training;
  trainingSub: Subscription;

  trainer:Trainer
  trainerSub:Subscription
  trainer$

  userDocuments:UserDocument[]

  canDownloadTest: Boolean = false
  canDownloadSurvey: Boolean = false
  canDownloadStatement = false

  displayOverview: String = "block";
  displayDocuments: String = "none";
  displayComments: String = "none";
  displayInstructor: String = "none";

  overviewButtonClass: String = "buttonSelected";
  documentsButtonClass: String = "button";
  commentsButtonClass: String = "button";
  instructorButtonClass: String = "button";
  user$: Observable<User>;
  comments
  trainingId
  trainingDocuments
  backgroundColor
  buttonStyle
  iconColor
  button
  buttonSelected
  documentBtn
  docBackground
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()
  constructor(public db: AngularFirestore, private router: Router, private authService: AuthService, private languageService: UserLanguageService, private userDashboardService: UserDashboardService, private localStorageService:LocalStorageService, private component: UserDashboardComponent) { }
  @ViewChild('insertForm') insertForm:NgForm
  ngOnInit(): void {
    this.langSub = this.languageService.lang$.subscribe((lang) =>{
      this.lang = lang;
    })
    
    this.authService.fetchCurrentUser().subscribe((user:User) => {
      console.log(user)
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.backgroundColor = "#bbbcbf"
          this.buttonStyle = "buttonEMS"
          this.iconColor = "colorEMS"
          this.button = "buttonTabEMS"
          this.buttonSelected = "buttonSelectedEMS"
          this.documentBtn = "#2e2d78"
          this.docBackground = "#bbbcbf"

        }else if(this.user.companyId == 2){
          this.backgroundColor = "#ecf0f9"
          this.buttonStyle = "buttonECG"
          this.iconColor = "colorECG"
          this.button = "buttonTabECG"
          this.buttonSelected = "buttonSelectedECG"
          this.documentBtn = "darkorange"
          this.docBackground = "#ecf0f9"
        }
        else{
          this.backgroundColor = "#ecf0f9"
          this.buttonStyle = "buttonECG"
          this.iconColor = "colorECG"
          this.button = "buttonTabECG"
          this.buttonSelected = "buttonSelectedECG"
          this.documentBtn = "darkorange"
          this.docBackground = "#ecf0f9"
        }
        this.overviewButtonClass = this.buttonSelected;
        this.documentsButtonClass = this.button;
        this.commentsButtonClass = this.button;
        this.instructorButtonClass = this.button;
        this.userDashboardService.fetchUsersDocuments(user.id).subscribe(response => {
          this.userDocuments = response as UserDocument[]
        })
        this.userDashboardService.fetchTrainingDocuments(user.id, this.localStorageService.getItem('trainingId')).subscribe(response => {
          this.trainingDocuments = response
          console.log("response")
          console.log(response)
        })
      }, 100);
      user.trainings.forEach(usrTr => {
        if(usrTr.trainingId == this.localStorageService.getItem('trainingId')){
          this.canDownloadSurvey = usrTr.surveyFlag
          this.canDownloadTest = usrTr.successful
          if(usrTr.status == 3){
            this.canDownloadStatement = true
          }
          else{
            this.canDownloadStatement = false
          }
        }
      });
    })
    this.trainingId = this.localStorageService.getItem('trainingId');
    this.comments = this.getComments()
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    
    this.trainingSub = this.userDashboardService.training$.subscribe((training) => {
      this.training = training;
    })

    this.trainer$ = this.userDashboardService.getTrainerForTraining(this.localStorageService.getItem('trainingId')).pipe(share());
    this.trainerSub = this.userDashboardService.trainer$.subscribe((trainer) => {
      this.trainer = trainer;
      console.log(trainer)
    })

    
  }
  @HostListener("click") onClick(){
    this.component.sideBarOpened = false
  }
  whatToDisplay(string: String) {
    switch (string) {
      case "overview":
        //divs
        this.displayOverview = "block";
        this.displayDocuments = "none";
        this.displayComments = "none";
        this.displayInstructor = "none";
        //buttons
        this.overviewButtonClass = this.buttonSelected;
        this.documentsButtonClass = this.button;
        this.commentsButtonClass = this.button;
        this.instructorButtonClass = this.button;
        break;
      case "documents":
        //divs
        this.displayOverview = "none";
        this.displayDocuments = "block";
        this.displayComments = "none";
        this.displayInstructor = "none";
        //buttons
        this.overviewButtonClass = this.button;
        this.documentsButtonClass = this.buttonSelected;
        this.commentsButtonClass = this.button;
        this.instructorButtonClass = this.button;
        break;
      case "instructor":
        //divs
        this.displayOverview = "none";
        this.displayDocuments = "none";
        this.displayComments = "none";
        this.displayInstructor = "flex";
        //buttons
        this.overviewButtonClass = this.button;
        this.documentsButtonClass = this.button;
        this.commentsButtonClass = this.button;
        this.instructorButtonClass = this.buttonSelected;
        break;
      case "comments":
        //divs
        this.displayOverview = "none";
        this.displayDocuments = "none";
        this.displayComments = "block";
        this.displayInstructor = "none";
        //buttons
        this.overviewButtonClass = this.button;
        this.documentsButtonClass = this.button;
        this.commentsButtonClass = this.buttonSelected;
        this.instructorButtonClass = this.button;
        break;
    }
  }

  goToTraining() {
    this.router.navigateByUrl('user/training');
  }

  downloadStatement(trId) {
    this.userDashboardService.downloadStatement(trId, this.lang, this.user.userName);
  }
  downloadSurveyResults(trId) {
    this.userDashboardService.downloadSurveyResults(trId, this.lang);
  }
  downloadTestResults(trId) {
    this.userDashboardService.downloadTestResults(trId, this.lang, this.user.userName);
  }

  getComments(){
    let comments = []
    let docRef = this.db.firestore.collection("trainings").doc(this.trainingId).collection('comments').orderBy('time', 'asc')
    //let userRef = await this.db.firestore.collection("users")
    docRef.onSnapshot(function(snapshot) {
        
        snapshot.docChanges().forEach( function(change) {
            let comment = change.doc.data()
            comment.dateTime = change.doc.data().time
            let timestamp = change.doc.data().time.seconds
            var date = new Date(timestamp*1000);
            var formatDate = date.getDate()+
            "/"+(date.getMonth()+1)+
            "/"+date.getFullYear()+
            " "+date.getHours()+
            ":"+date.getMinutes()
            comment.time = formatDate
            let userId = comment.userId
            //let user = await userRef.doc(userId).get()
            //let imgPath = user.data().profileImagePath
            //comment.profileImagePath = imgPath
            comments.push(comment)
        });
      });
      console.log(comments)
      return comments
  }

  submitComment(){
    if(this.insertForm.value.text != "" && this.insertForm.value.text != null){
      let docRef = this.db.firestore.collection("trainings").doc(this.trainingId).collection('comments').doc()
      let comment = {
        id: docRef.id,
        trainingId: this.trainingId,
        userId: this.insertForm.value.userId,
        fullName: this.insertForm.value.firstName + " " + this.insertForm.value.lastName,
        time: new Date(),
        text: this.insertForm.value.text,
        imagePath: this.user.profileImagePath
    }
    
    docRef.set(comment)
    this.insertForm.controls.text.reset()

    }
  }


}
