<div class="container">
    <div class='row' >
      <div class = "col-11 col-sm-11" style = "margin: auto; margin-top: 11%; text-align: left;">
        <h1 class="doneLabel" style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: white; font-size: 40px;">{{labels.lblThankYou}}</h1>
        <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">
            {{labels.lblThankYouforFilling}}. <br>
            {{labels.lblYourOpinion}}. <br> 
            <!-- {{labels.lblKlikomNaDugme}}. -->
        </h3>
        <div class="row" style = "text-align: center;">
          <div class="col-12" *ngIf = "training.surveyResults != true" style = "margin: auto;">
            <button class="login-button" (click)="goToHomePage()">
                <div class = "col-11" style = "margin: auto; text-align: center;">
                  <h3 class = "loginBtnLabel">{{labels.lblTraining}}</h3>
                </div>
            </button>
          </div>
          <!-- <div class="col-12 col-sm-6" *ngIf = "training.surveyResults == true" style = "margin: auto;">
            <button class="login-button" style = "border: 1px solid #90A2F8 !important; color: #90A2F8; background: transparent" (click)="downloadSurveyResults(training.id)">
                <div class = "col-11" style = "margin: auto; text-align: center;">
                  <h3 class = "loginBtnLabel">{{labels.lblSurvey}}</h3>
                </div>
            </button>
          </div> -->
          <div class="col-12 col-sm-6" *ngIf = "training.surveyResults == true" style = "margin: auto;">
            <button class="login-button" (click)="goToHomePage()">
                <div class = "col-11" style = "margin: auto; text-align: center;">
                  <h3 class = "loginBtnLabel">{{labels.lblTraining}}</h3>
                </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
