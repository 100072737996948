import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component'
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { PostsComponent } from './modules/posts/posts.component'
import { LayoutAlignDirective } from '@angular/flex-layout';
import { LoginComponent } from './modules/login/login.component';
import { UserDashboardComponent } from './layouts/user-dashboard/user-dashboard.component';
import { UserDashboardContentComponent } from './modules/user-dashboard-content/user-dashboard-content.component';
import { UserProfileComponent } from './modules/user-profile/user-profile.component';
import { UserTrainingComponent } from './modules/user-training/user-training.component'
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { TestComponent } from './modules/test/test.component';
import { AdminUserComponent } from './modules/admin-user/admin-user.component';
import { AdminTrainingComponent } from './modules/admin-training/admin-training.component';

import { AdminTrainingDetailComponent } from './modules/admin-training-detail/admin-training-detail.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { AdminTrainingSectionDetailComponent } from './modules/admin-training-section-detail/admin-training-section-detail.component';
import { AdminDepartmentComponent } from './modules/admin-department/admin-department.component';
import { AdminTestComponent } from './modules/admin-test/admin-test.component';
import { AdminChangePasswordComponent } from './modules/admin-change-password/admin-change-password.component';
import { AdminTestQuestionComponent } from './modules/admin-test-question/admin-test-question.component'
import { AdminTestQuestionAnswerComponent } from './modules/admin-test-question-answer/admin-test-question-answer.component'
import { AdminLoginComponent } from './modules/admin-login/admin-login.component';
import { AuthGuardService } from './modules/services/authGuard-service/auth-guard.service';
import { UserTrainingDetailsComponent } from './modules/user-training-details/user-training-details.component'
import { EditUserProfileComponent } from './modules/edit-user-profile/edit-user-profile.component';
import { SurveyComponent } from './modules/survey/survey.component';
import { PreTestComponent } from './modules/pre-test/pre-test.component';
import { GenerateReportsComponent } from './modules/generate-reports/generate-reports.component';
import { AdminChatComponent } from './modules/admin-chat/admin-chat.component';
import { UserChatComponent } from './modules/user-chat/user-chat.component';
import { UserNotificationComponent } from './modules/user-notification/user-notification.component';
import { UserMessagesComponent } from './modules/user-messages/user-messages.component';
import { WelcomePageComponent } from './modules/welcome-page/welcome-page.component';
import { AdminTrainerComponent } from './modules/admin-trainer/admin-trainer.component';
import { AdminUserSectorComponent } from './modules/admin-user-sector/admin-user-sector.component';
import { AdminUserProfileComponent } from './modules/admin-user-profile/admin-user-profile.component';
import { AdminAboutTrainingComponent } from './modules/admin-about-training/admin-about-training.component';
import { AdminAboutSectorsComponent } from './modules/admin-about-sectors/admin-about-sectors.component';
import { AdminAboutTestComponent } from './modules/admin-about-test/admin-about-test.component';
import { ControlQuestionComponent } from './modules/control-question/control-question.component';
import { ControlQuestionAnswerComponent } from './modules/control-question-answer/control-question-answer.component';
import { AdminTrainerProfileComponent } from './modules/admin-trainer-profile/admin-trainer-profile.component';
import { FaqPageComponent } from './modules/faq-page/faq-page.component';
import { AdminFaqComponent } from './modules/admin-faq/admin-faq.component';
import { AdminAdministratorComponent } from './modules/admin-administrator/admin-administrator.component';
import { SectionTestComponent } from './modules/section-test/section-test.component';
import { ConfirmPasswordComponent } from './modules/confirm-password/confirm-password.component';
import { UserCalendarComponentComponent } from './modules/user-calendar-component/user-calendar-component.component';
import { AdminCalendarComponent } from './modules/admin-calendar/admin-calendar.component';
import { ZoomMeetingComponent } from './modules/zoom-meeting/zoom-meeting.component';
import { AdminZoomMeetingsComponent } from './modules/admin-zoom-meetings/admin-zoom-meetings.component';
import { ChatZoomMeetingComponent } from './modules/user-messages/chat-zoom-meeting/chat-zoom-meeting.component';
import { AdminChatMeetingComponent } from './modules/admin-chat/admin-chat-meeting/admin-chat-meeting.component';
import { AdminZoomMeetingComponent } from './modules/admin-zoom-meeting/admin-zoom-meeting.component';
import { ZoomVerifyComponent } from './modules/zoom-verify/zoom-verify.component';
import { ZoomTestComponent } from './modules/zoom-test/zoom-test.component'
import { UserForgotPasswordComponent } from './modules/login/user-forgot-password/user-forgot-password.component';
import { UserBeforeTestMessageComponent } from './modules/user-training/user-before-test-message/user-before-test-message.component';
import { UserBeforeSurveyMessageComponent } from './modules/survey/user-before-survey-message/user-before-survey-message.component';
import { UserBeforePreTestComponent } from './modules/pre-test/user-before-pre-test/user-before-pre-test.component';
import { UserPretestMessageComponent } from './modules/pre-test/user-pretest-message/user-pretest-message.component';
import { MessageBeforeSectionTestComponent } from './modules/section-test/message-before-section-test/message-before-section-test.component';
import { SplashScreenComponent } from './modules/splash-screen/splash-screen.component';
import { RangListComponent } from './modules/rang-list/rang-list.component';
import { TermsOfUseModalComponent } from './modules/terms-of-use-modal/terms-of-use-modal.component';
import {GlobalTrainingsComponent} from './modules/global-trainings/global-trainings.component';

const routes: Routes = [
  {
    path: 'admin',
    component: DefaultComponent,
    children: [{
      path: '',
      component: DashboardComponent
    },
    {
      path: 'posts',
      component: PostsComponent
    },
    {
      path:"trainer-profile/:id",
      component: AdminTrainerProfileComponent
    },
    {
      path: 'trainings',
      component: AdminTrainingComponent,
      children: [
        {
          path: 'training-detail/:id',
          component: AdminTrainingDetailComponent,
          children: [
            {
              path: 'section-detail/:sectionId',
              component: AdminTrainingSectionDetailComponent
             
            }
          ]
        },
        {
            path: 'control-question/:trainingId/:sectionId/:id',
            component: ControlQuestionComponent
        },
   
        {
          path: 'control-question-answers/:trainingId/:sectionId/:id/:questionId',
          component: ControlQuestionAnswerComponent
        }
      ]
    },
    {
      path: 'training-details/:id',
      component: AdminAboutTrainingComponent,
    },
    {
      path: "users",
      component: AdminUserComponent,
    },
    {
      path: "administrators",
      component: AdminAdministratorComponent,
    },
    {
      path: 'usersInDep/:id',
      component: AdminUserSectorComponent,
    },
    {
      path: "user-profile/:id",
      component: AdminUserProfileComponent
    },
    {
      path: 'departments',
      component: AdminDepartmentComponent,
    },
    {
      path: 'tests',
      component: AdminTestComponent,
      children:[
        {
        path:':id',
        component:AdminTestQuestionComponent,
        children:[
          {
          path:':questionId/question-answers',
          component:AdminTestQuestionAnswerComponent
          }
        ]
        }
      ]
    },
    {
      path: 'test-details/:id',
      component: AdminAboutTestComponent
    },
    {
      path: 'trainers',
      component: AdminTrainerComponent
    },
    {
      path: 'generate-reports',
      component: GenerateReportsComponent
    },
    {
      path: 'messages',
      component: AdminChatComponent
    },
    {
      path: 'change-password',
      component: AdminChangePasswordComponent
    },
    {
      path: 'faq',
      component: AdminFaqComponent
    },
    {
      path: 'calendar',
      component: AdminCalendarComponent,
    },
    {
      path: "zoom-meetings",
      component: AdminZoomMeetingsComponent
    },
    {
      path: "chat-meeting",
      component: AdminChatMeetingComponent
    },
    {
      path: "zoom-meeting/:id",
      component: AdminZoomMeetingComponent
    }
    ]
  },
  { path: 'global-trainings', component: GlobalTrainingsComponent},
  { path: 'login', component: AdminLoginComponent },
  { path: '', component: LoginComponent },
  { path: 'zoomverify/verifyzoom.html', component: ZoomVerifyComponent},
  { path: 'confirm-address/:id', component: ConfirmPasswordComponent },
  { path: 'reset-password', component: UserForgotPasswordComponent },
  {
    path: 'terms-of-use',
    component: TermsOfUseModalComponent
  },
  {
    path: 'user',
    component: UserDashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        component: UserDashboardContentComponent,
        //canActivate:[AuthGuardService]

      },
      {
        path: 'calendar',
        component: UserCalendarComponentComponent,
        //canActivate:[AuthGuardService]

      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        //canActivate:[AuthGuardService]
      },
      {
        path: 'training-details',
        component: UserTrainingDetailsComponent
      },
      {
        path: 'training',
        component: UserTrainingComponent,
        //canActivate:[AuthGuardService]
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        //canActivate:[AuthGuardService]
      },
      {
        path: 'test',
        component: TestComponent,
        //canActivate:[AuthGuardService]
      },
      {
        path: 'section-test',
        component: SectionTestComponent
      },
      {
        path: 'messages',
        component: UserMessagesComponent
      },
      {
        path: 'survey',
        component: SurveyComponent
      },
      {
        path: 'pretest',
        component: PreTestComponent
      },
      {
        path: 'welcome',
        component: WelcomePageComponent
      }, 
      {
        path: 'faq',
        component: FaqPageComponent
      },
      {
        path: 'zoom-meeting/:id',
        component: ZoomMeetingComponent,
      },
      {
        path: 'chat-meeting',
        component: ChatZoomMeetingComponent
      },
      {
        path: 'splash-screen',
        component: SplashScreenComponent
      },
      {
        path: 'zoom-test',
        component: ZoomTestComponent
      },
      {
        path: 'start-test',
        component: UserBeforeTestMessageComponent
      },
      {
        path: 'start-survey',
        component: UserBeforeSurveyMessageComponent
      },
      {
        path: 'start-pretest',
        component: UserBeforePreTestComponent
      },
      {
        path: 'start-section-test',
        component: MessageBeforeSectionTestComponent
      },
      {
        path: 'rang-list',
        component: RangListComponent
      },
      
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
