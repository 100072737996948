<div class = "login-page">
  <div class = "loginForm">
    <div style = "text-align: center; margin-top: 10%;">
      <img class="lesteLogo" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/LogoLESTE.png?alt=media&token=f8976808-1888-4484-948b-b6f268bdbee3" alt = "leste icon">
    </div>
    <div class = "forma1">
      <div class="loginLabel">
        <h2 class="loginLabel">{{labels.lblForgotPass}}</h2>
      </div>
      <form action="" #forgotPassForm="ngForm" style = "margin-top: 7%;" (ngSubmit)="onSubmit(forgotPassForm)">
        <mat-form-field class = "no-line inputFieldForm" appearance="none">
          <span class = "inputIcon" style = "display: inline-block">
            <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/emailLoginIcon.png?alt=media&token=0999ab04-a16b-44ca-b745-326eb4edcd23" alt = "email login icon">
          </span>
         <span style = "display: inline-block; vertical-align: middle; padding-left: 10px;">
          <input
          matInput
          placeholder="abc@email.com"
          required
          name="email"
          ngModel
          #email="ngModel">
         </span>
        </mat-form-field> <br>
        <button class="login-button">
          <div class = "row">
            <div class = "col-1"></div>
            <div class = "col-9" style = "text-align: center; margin: auto;">
              <h3 class = "loginBtnLabel">{{labels.lblRequestAReset}}</h3>
            </div>
           <div class = "col-1"><img style = "float: right; padding-right: 15px;" src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/loginIcon.png?alt=media&token=331da5b1-d77f-4c81-89a1-0e35b3feea30'></div>
          </div>
        </button>
      </form>

      <div class = "row">
        <div class = "col-11" style="font-style: normal;
        font-weight: normal;
        font-size: 16px; text-align: center; margin: auto; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; cursor: pointer" (click) = "backToLogin()">
            {{labels.lblBackToLogin}}
        </div>
      </div>
    </div>
  </div>
</div>