import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { Training } from 'src/app/models/training.model';
import { User } from 'src/app/models/user.model';
import { UserXTrainings } from 'src/app/models/userXTrainings.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { TestComponent } from '../../test/test.component';
import { UserDashboardService } from '../../user-dashboard.service';

@Component({
  selector: 'app-user-before-test-message',
  templateUrl: './user-before-test-message.component.html',
  styleUrls: ['./user-before-test-message.component.scss'],
})
export class UserBeforeTestMessageComponent implements OnInit {
  test$: Observable<Test>
  test: Test
  testSub: Subscription

  lang
  langSub: Subscription

  maxPoints: number

  labels;
  labelsSub: Subscription;

  training$: Observable<Training>
  training: Training
  trainingSub: Subscription

  user
  userSub: Subscription
  button1
  button2
  color

  userXTraining$: Observable<UserXTrainings>
  userXTrainingSub: Subscription
  userXTraining
  constructor(private authService: AuthService,
    private userDashboardService: UserDashboardService, private languageService: UserLanguageService,
    private router: Router, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.langSub = this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    })
    this.localStorageService.setItem('currentQuestionIndex', 0)
    console.log("BREEE")
    this.test$ = this.userDashboardService.getTestForTraining(this.localStorageService.getItem("testId")).pipe(share());
    this.testSub = this.userDashboardService.test$.subscribe((test) => {
      setTimeout(() => {
        this.test = test;
        console.log("TEST FROM MESSAGE BEFORE TEST ")
        console.log(this.test)
        this.maxPoints = test.minPercentage
      }, 400);
    })
     this.training$ = this.userDashboardService.getTraining().pipe(share());
     this.trainingSub = this.userDashboardService.training$.subscribe((training) => {
       this.training = training;
     })

    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.userXTraining$ = this.userDashboardService.getUserXTrainings(this.localStorageService.getItem('trainingId')).pipe(share());
    this.userXTrainingSub = this.userDashboardService.userXTraining$.subscribe((usrXTr: UserXTrainings) => {
        console.log("user x training msg after section test")
        this.userXTraining = usrXTr;
        console.log(this.userXTraining)
    })
   
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.button1 = "buttonEMS"
          this.button2 = "buttonEMS1"
          this.color = "#49D14F"

        }else if(this.user.companyId == 2){
          this.button1 = "buttonECG"
          this.button2 = "buttonECG1"
          this.color = "#49D14F"
        }
        else{
          this.button1 = "buttonECG"
          this.button2 = "buttonECG1"
          this.color = "#49D14F"
        }
      }, 200);
    });
  }

  goToTestPage() {
    //window.location.reload()
    //this.testComponent.timer.start()
    this.router.navigateByUrl('user/test')
  }

  downloadStatement(trId) {
    this.userDashboardService.downloadStatement(trId, this.lang, "");
  }

}
