import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms'
import { Observable, Subscription } from 'rxjs';
import { UserDashboardService } from './../user-dashboard.service'
import { User } from '../../../../../Leste 3.0/models/User'
import { AuthService } from '../services/auth-service/auth.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { Training } from 'src/app/models/training.model';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { UserDocument } from 'src/app/models/userDocument.model';
import { share } from 'rxjs/operators';
import { UserBeforePreTestComponent } from '../pre-test/user-before-pre-test/user-before-pre-test.component';
import { MatDialog } from '@angular/material/dialog';
import { Unit } from 'src/app/models/unit.model';
import { Test } from 'src/app/models/test.model';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { EditUserProfileComponent } from '../edit-user-profile/edit-user-profile.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  host: { '(click)': 'onClick()'}
})
export class UserProfileComponent implements OnInit {

  user: User
  userSub: Subscription;
  user$: Observable<User>;
  labels;
  labelsSub: Subscription;

  lang
  langSub: Subscription;

  trainings:Training[];
  trainingsSub: Subscription;

  canDownloadTest: Boolean = false
  canDownloadSurvey: Boolean = false
  canDownloadStatement = false

  userDocuments:UserDocument[]
  test$: Observable<Test>
  currentUnit$: Observable<Unit>
  @ViewChild('userForm') userForm: NgForm
  backgroundColor
  buttonStyle
  buttonStyle1
  iconColor
  docBackground
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()
  constructor(public dialog: MatDialog, private localStorageService: LocalStorageService, private userDashboardService: UserDashboardService, private authService: AuthService, private languageService: UserLanguageService, private router: Router, private component: UserDashboardComponent) { }

  ngOnInit(): void {
    this.langSub = this.languageService.lang$.subscribe((lang) =>{
      this.lang = lang;
      console.log("LANG:::", this.lang)
    })
    this.localStorageService.removeItem('currentUnit')
    this.user$ = this.authService.getCurrentUser().pipe(share());
    this.userSub = this.authService.fetchCurrentUser().subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        this.userDashboardService.fetchUsersDocuments(user.id).subscribe(response => {
          this.userDocuments = response as UserDocument[]
        })
      }, 100);
    });
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.authService.getCurrentUsersTrainings().pipe(share());
    this.trainingsSub = this.authService.usersTrainings$.subscribe((trainings) =>{
      this.trainings = trainings;
    })

  }
  @HostListener("click") onClick(){
    console.log("User Click using Host Listener")
    this.component.sideBarOpened = false
  }

  onSubmit() {
    console.log(this.userForm.value)
    this.userDashboardService.editUserProfile(this.userForm.value)
  }

  ondDestroy() {
    this.userSub.unsubscribe();
  }

  openChangePasswordDialog() {
    console.log("USER: ", this.user)
    const dialogRef = this.dialog.open(UserChangePasswordComponent, {
      width: '550px',
      data: this.user
    });
  }

  openEditProfileDialog() {
    console.log("USER: ", this.user)
    const dialogRef = this.dialog.open(EditUserProfileComponent, {
      width: '550px',
      data: this.user
    });

    dialogRef.afterClosed().subscribe(() => {
      this.user$ = this.authService.getCurrentUser().pipe(share());
      this.userSub = this.authService.fetchCurrentUser().subscribe((user: User) => {
        this.user = user;
        setTimeout(() => {
          this.userDashboardService.fetchUsersDocuments(user.id).subscribe(response => {
            this.userDocuments = response as UserDocument[]
          })
        }, 100);
      });
    })
  }

  enableEditing() {

  }
  goToTrainingDetails(training:Training){
    this.userDashboardService.setTraining(training)
    this.router.navigateByUrl('user/training-details');
  }
  changeStatus(training: Training, status: Number) {
    this.userDashboardService.changeTrainingStatus(training.id, status).subscribe((result) => {
      console.log(result)
    })
  }

  openTestDialog(): void {
    const dialogRef = this.dialog.open(UserBeforePreTestComponent, {
      disableClose: true,
      width: '350px'
    });

  }
  
  goToTraining(training: Training) {
    if (training.status == 1) {
      this.userDashboardService.setTraining(training)
      this.router.navigateByUrl('user/welcome');
    } else {
      this.userDashboardService.setTraining(training)

      this.userDashboardService.setTraining(training)

      const tr = this.user.trainings.filter((t) => t.trainingId == training.id)[0]

      this.currentUnit$ = this.userDashboardService.getCurrentUnit(training.id).pipe(share());

      if (!tr.pretestFlag && training.pretestId != "") {
        this.test$ = this.userDashboardService.getTestForTraining(this.localStorageService.getItem('pretestId')).pipe(share())
        this.router.navigateByUrl('user/pretest');
        this.openTestDialog()
      }
      else {
        this.router.navigateByUrl('user/training');
      }
      this.changeStatus(training, 2)
    }
  }

  downloadStatement(trId) {
    this.userDashboardService.downloadStatement(trId, this.lang, this.user.userName);
  }
  downloadSurveyResults(trId) {
    this.userDashboardService.downloadSurveyResults(trId, this.lang);
  }
  downloadTestResults(trId) {
    this.userDashboardService.downloadTestResults(trId, this.lang, this.user.userName);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  selectLang(lang) {
    this.languageService.setLanguage(lang);
  }
}
