<mat-drawer-container style="height: 100%;">
  <mat-drawer-content>
    <div style="width: 65%; margin: auto;">
      <div style="text-align: center; margin-top: 1.5%;">
        <img class="lesteLogo" width="350px"
          src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/LogoLESTE.png?alt=media&token=f8976808-1888-4484-948b-b6f268bdbee3"
          alt="nis logo">
      </div>
      <h3 style="margin-top: 3%; margin-bottom: 5%; text-align: center; font-size: 4.9vh; font-weight: bold;">
        {{labels.lblTermsOfUse}}</h3>
      <div class="pdfViewer"
        style="height: 370px; position: relative; overflow-y: scroll; overflow-x: hidden; background-color: transparent; font-family: Lato, 'Alegreya-Sans'; font-size: 18.5px;">
        <p style="color: #0E0D35; opacity: 0.5;">
          <b>Услови коришћења</b> <br><br>

          Добродошли на ЛЕСТЕ платформу за електронску едукацију.

          <br />
          <br />

          ЛЕСТЕ платформа Вам омогућава коришћење садржаја и услуга платформе под доле наведеним условима (у даљем
          тексту: „Услови коришћења"). Ови Услови коришћења се примењују на све садржаје и услуге ЛЕСТЕ платформе.
          Сматра се да свако лице које приступи ЛЕСТЕ платформи или било ком његовом делу јесте у сваком тренутку у
          потпуности упознато са Условима коришћења, као и свим евентуалним ризицима, да исте разуме и прихвата у
          целости, те да садржаје и услуге платформе користи искључиво за личну употребу и на сопствену одговорност.

          <br />
          <br />

          Ауторска права

          <br />

          ЛЕСТЕ је искључиви и једини власник ауторских права на све садржаје (текстуалне, визуелне и аудио материјале).
          Неовлашћено коришћење било ког дела ЛЕСТЕ платформе без писане дозволе ЛЕСТЕ (укључујући, неовлашћено
          коришћење од стране посетилаца у сврхе које нису личне, пренос било ког дела веб сајта у другим медијима и
          сл.) сматра се кршењем ауторских права ЛЕСТЕ, у ком случају је ЛЕСТЕ овлашћен да оствари судску заштиту својих
          права и захтева одговарајућу накнаду штете.

          <br />
          <br />
          ЛЕСТЕ задржава право измене, укидања (привременог и трајног) било којег садржаја или услуге на платформи без
          обавезе претходне најаве. Све садржаје ЛЕСТЕ платформе користите искључиво на властиту одговорност. ЛЕСТЕ се
          не може ни у ком случају сматрати одговорном за било какву штету насталу коришћењем.

          <br />
          <br />

          Корисницима ЛЕСТЕ платформе строго је забрањено: објављивање, слање и размена информација за које посетилац
          зна или претпоставља да су лажне, а чије би коришћење могло да нанесе штету другим корисницима, лажно
          представљање, односно представљање у име другог правног или физичког лица, објављивање, слање и размена
          садржаја који су заштићени ауторским правом, објављивање, слање и размена нежељених садржаја корисницима без
          њиховог пристанка или тражења, или путем обмане, свесно објављивање, слање и размена садржаја који садрже
          вирусе или сличне програме направљене у сврху уништавања или ограничавања рада било којег
          компјутерског софтвера и/или хардвера и телекомуникационе опреме, прикупљање, чување и објављивање личних
          података других посетилаца и корисника ЛЕСТЕ платформе.
          <br>
          <br>

          Заштита приватности

          <br>
          ЛЕСТЕ поштује приватност корисника платформе. Податке из процеса регистрације, као и остале податке о
          кориснику, ЛЕСТЕ неће давати на увид трећој страни. Подаци о кориснику неће бити доступни трећој страни, осим
          у случају када је таква обавеза регулисана законом и у случају тешког кршења Услова коришћења.

        </p>
        <div class="row fadeOut" style="width: 100%; position: sticky -webkit-sticky; bottom: 0">
          <button class="login-button" (click)="acceptTerms()">
            <div class="row">
              <div class="col-1"></div>
              <div class="col-9" style="margin: auto; text-align: center;">
                <h3 class="loginBtnLabel">{{labels.lblAccept}}</h3>
              </div>
              <div class="col-1"><img style="float: right; padding-right: 15px;"
                  src='https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/loginIcon.png?alt=media&token=331da5b1-d77f-4c81-89a1-0e35b3feea30'
                  alt="login icon">
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>