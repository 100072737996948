<app-user-spinner *ngIf="training == null || user == null || labels == null || userXTraining == null || test == null"></app-user-spinner>
<div class="container">
  <!-- <img src="https://collegiateacademy.co.uk/wp-content/uploads/2021/01/50192-9-exam-image-hq-image-free-png.png" alt=""> -->
  <div class='row passedTest' *ngIf="data.passedTest">
    <div class = "col-11 col-sm-11" style = "margin: auto; margin-top: 7%; margin-bottom: 7%; text-align: left;">
      <h1 class="doneLabel" style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: white; font-size: 40px;">{{labels.lblYouPassed}}</h1>
      <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">{{labels.lblCongrats}} <br>
        {{training.name}}. <br>
        {{labels.lblYourSuccesRate}} <span [style.color] = "color" style = "font-weight: bold;">{{data.percentage}}%</span><br>
        {{labels.lblYouAnswered}} <span [style.color] = "color" style = "font-weight: bold;">{{data.corrcetAnswersCounter}}</span> {{labels.lblOf}} <span [style.color] = "color" style = "font-weight: bold;">{{data.questions}}</span> {{labels.lblQuestions}}<br>
        <!-- {{labels.lblYouHaveWon}}  <span [style.color] = "color" style = "font-weight: bold;">{{data.points}}</span> od <span [style.color] = "color" style = "font-weight: bold;">{{data.maxPoints}}</span> {{labels.lblPoints}}. -->
      </h3>
      <div class="row" *ngIf="(!userXTraining.surveyFlag) && (userXTraining.surveyId != '')">
        <div class="col-12 col-sm-6" *ngIf = "training.testResults == true" style = "margin: auto;">
          <button class="login-button" style = "border: 1px solid #90A2F8 !important; color: #90A2F8; background: transparent" (click)="downloadTestResults(training.id)">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblTest}}</h3>
              </div>
          </button>
        </div>
        <div class="col-12 col-sm-6" *ngIf = "training.testResults == true" style = "margin: auto;">
          <button class="login-button" (click)="goToSurvey()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblNext}}</h3>
              </div>
          </button>
        </div>
        <div class="col-11" *ngIf = "!training.testResults" style = "margin: auto;">
          <button class="login-button" (click)="goToSurvey()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblNext}}</h3>
              </div>
          </button>
        </div>
      </div>
      <div class="row" *ngIf="userXTraining.surveyFlag || userXTraining.surveyId == ''">
        <div class="col-12 col-sm-6" *ngIf = "training.testResults == true" style = "margin: auto;">
          <button class="login-button" style = "border: 1px solid #90A2F8 !important; color: #90A2F8; background: transparent" (click)="downloadTestResults(training.id)">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblTest}}</h3>
              </div>
          </button>
        </div>
        <div class="col-12 col-sm-6" *ngIf = "training.testResults == true" style = "margin: auto;">
          <button class="login-button" (click)="goToTrainingPage()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblTraining}}</h3>
              </div>
          </button>
        </div>
        <div class="col-12" *ngIf = "!training.testResults" style = "margin: auto;">
          <button class="login-button" (click)="goToTrainingPage()">
              <div class = "col-5" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblTraining}}</h3>
              </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class='row didntPassedTest' *ngIf="!data.passedTest">
    <div class = "col-11 col-sm-11" style = "margin: auto; margin-top: 11%; margin-bottom: 7%; text-align: left;">
      <h1 class="highlightOffLabel" style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: red; font-size: 40px;">{{labels.lblYoudDidntPassed}}</h1>
      <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">{{labels.forPassingTheTest}} 
        {{labels.lblMinPoints}}
        <span [style.color] = "color" style = "font-weight: bold;">{{data.minPercentage}}%.</span> <br>
        {{labels.lblYourSuccesRate}} <span [style.color] = "color" style = "font-weight: bold;">{{data.percentage}}%.</span><br>
        {{labels.lblYouAnswered}} <span [style.color] = "color" style = "font-weight: bold;">{{data.corrcetAnswersCounter}}</span> {{labels.lblOf}} <span [style.color] = "color" style = "font-weight: bold;">{{data.questions}}</span> {{labels.lblQuestions}}<br>
        <!-- {{labels.lblYouHaveWon}}  <span [style.color] = "color" style = "font-weight: bold;">{{data.points}}</span> {{labels.lblOf}} <span [style.color] = "color" style = "font-weight: bold;">{{data.maxPoints}}</span> {{labels.lblPoints}}. -->
      </h3>
        
        <h2  *ngIf="data.numOfTestDrops == test.numberOfTestDrops" style = "color: red; opacity: 0.8; font-weight: bold;">{{test?.customFailMessage || ""}}</h2>
      <h3 *ngIf="data.timeIsUp" [style.color] = "color" style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">{{labels.lblTimeIsUp}}</h3>
      <div class="row">
        <div class="col-12 col-sm-6" style = "margin: auto;">
          <button class="login-button"  style = "border: 1px solid #90A2F8 !important; color: #90A2F8; background: transparent" (click)="goToTrainingPage()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblTraining}}</h3>
              </div>
          </button>
        </div>
        <!-- <div class="col-12 col-sm-6" style = "margin: auto;">
          <button class="login-button" style = "border: 1px solid #90A2F8 !important; color: #90A2F8; background: transparent" (click)="goToHomePage()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblHomePage}}</h3>
              </div>
          </button>
        </div> -->
        <!-- <div class="col-12 col-sm-6" *ngIf = "btnTryAgain" style = "margin: auto;">
          <button class="login-button" (click)="goToTestPage()">
              <div class = "col-12" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblTryAgain}}</h3>
              </div>
          </button>
        </div> -->
      </div>
    </div>
  </div>
</div>