import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Test } from 'src/app/models/test.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';

@Component({
  selector: 'app-message-after-zoom-test',
  templateUrl: './message-after-zoom-test.component.html',
  styleUrls: ['./message-after-zoom-test.component.scss']
})
export class MessageAfterZoomTestComponent implements OnInit {

  labels;
  lang;
  userSub: any;
  user: User;
  button1;
  button2;
  color;

  constructor(public dialogRef: MatDialogRef<MessageAfterZoomTestComponent>,
          @Inject(MAT_DIALOG_DATA) public data,
          private userLanguageService: UserLanguageService,
          private router: Router,
          private authService: AuthService,
          private localStorageService: LocalStorageService,
          private userDashBoardService: UserDashboardService) { }

  ngOnInit(): void {

    this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    });
    
    this.userLanguageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });

    this.userSub = this.authService.user$.subscribe((user) => {
          this.user = user
          setTimeout(() => {
            if(this.user.companyId == 1){
              this.button1 = "buttonEMS"
              this.button2 = "buttonEMS1"
              this.color = "#c32a2a"
            
            }else if(this.user.companyId == 2){
              this.button1 = "buttonECG"
              this.button2 = "buttonECG1"
              this.color = "rgb(241,106,100)"
            }
            else{
              this.button1 = "buttonECG"
              this.button2 = "buttonECG1"
              this.color = "rgb(241,106,100)"
            }
          }, 200);
    })

  }

  goToTestPage() {
    this.dialogRef.close();
    this.router.navigateByUrl("user/zoom-test");
  }

  goToHomePage() {
    this.dialogRef.close();
    this.router.navigateByUrl("user/dashboard");
  }

  restartTest() {

    this.localStorageService.setItem('currentQuestionIndex', 0)
    this.localStorageService.setItem('questionScores', [])
    this.localStorageService.setItem('test', null)
    this.localStorageService.setItem('minutesLeft', this.data.test.durationTime)
    this.localStorageService.setItem('secondsLeft',0)
    this.dialogRef.close()
    this.router.navigateByUrl('user/zoom-test')
    window.location.reload();

  }

  downloadTestResults(meetingId) {
    this.userDashBoardService.downloadTestResults(meetingId, this.lang, this.user.userName);
  }

}
