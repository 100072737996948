import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DefaultComponent} from './default.component'
import {DashboardComponent} from './../../modules/dashboard/dashboard.component'
import { RouterModule } from '@angular/router';
import { SharedModule } from './../../shared/shared.module'  
import { MatSidenavModule} from '@angular/material/sidenav'
import {MatDividerModule} from '@angular/material/divider'
import {MatCardModule} from '@angular/material/card'
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardService} from './../../modules/dashboard.service'
import { MatTableModule} from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button';
import {MatInputModule} from '@angular/material/input'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import { LoginComponent } from 'src/app/modules/login/login.component';
import { MatCheckboxModule} from '@angular/material/checkbox'
import {MatTabsModule} from '@angular/material/tabs'
import { HttpClientModule } from '@angular/common/http';
import { UserProfileComponent } from './../../modules/user-profile/user-profile.component'
import {AdminUserComponent} from './../../modules/admin-user/admin-user.component'
import { MatSortModule } from '@angular/material/sort'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {MatDialogModule} from '@angular/material/dialog'
import {DeleteDialogComponent} from './../../modules/admin-user/delete-dialog/delete-dialog.component'
import {EditDialogComponent} from './../../modules/admin-user/edit-dialog/edit-dialog.component'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatSelectModule} from '@angular/material/select'
import { InsertDialogComponent } from 'src/app/modules/admin-user/insert-dialog/insert-dialog.component';
import {AdminTrainingComponent} from './../../modules/admin-training/admin-training.component'
import {UserTrainingsDialogComponent} from './../../modules/admin-user/user-trainings-dialog/user-trainings-dialog.component'

import {AdminTrainingDetailComponent} from './../../modules/admin-training-detail/admin-training-detail.component'
import {EditDialogComponent as EditTrainingDialog}  from './../../modules/admin-training/edit-dialog/edit-dialog.component'
import {DeleteDialogComponent as DeleteTrainingDialog} from './../../modules/admin-training/delete-dialog/delete-dialog.component'
import {InsertDialogComponent as InsertTrainingDialog} from './../../modules/admin-training/insert-dialog/insert-dialog.component'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {AdminTrainingSectionDetailComponent} from './../../modules/admin-training-section-detail/admin-training-section-detail.component'
import {InsertDialogComponent as InsertTrainingSectionDialog} from './../../modules/admin-training-detail/insert-dialog/insert-dialog.component'
import {DeleteDialogComponent as DeleteTrainingSectionDialog} from './../../modules/admin-training-detail/delete-dialog/delete-dialog.component'
import {EditDialogComponent as EditTrainingSectionDialog} from './../../modules/admin-training-detail/edit-dialog/edit-dialog.component'
import {DragDropModule} from '@angular/cdk/drag-drop'
import {InsertDialogComponent as InsertSectionUnitDialog} from './../../modules/admin-training-section-detail/insert-dialog/insert-dialog.component'
import {DeleteDialogComponent as DeleteSectionUnitDialog} from './../../modules/admin-training-section-detail/delete-dialog/delete-dialog.component'
import {EditDialogComponent as EditSectionUnitDialog} from './../../modules/admin-training-section-detail/edit-dialog/edit-dialog.component'
import {AdminDepartmentComponent} from './../../modules/admin-department/admin-department.component' 
import {EditDialogComponent as EditDepartmentDialog} from './../../modules/admin-department/edit-dialog/edit-dialog.component'
import {InsertDialogComponent as InsertDepartmentDialog} from './../../modules/admin-department/insert-dialog/insert-dialog.component'
import {DeleteDialogComponent as DeleteDepartmentDialog} from './../../modules/admin-department/delete-dialog/delete-dialog.component'
import {AdminTestComponent} from './../../modules/admin-test/admin-test.component'
import {EditDialogComponent as EditTestDialog} from './../../modules/admin-test/edit-dialog/edit-dialog.component'
import {InsertDialogComponent as InsertTestDialog} from './../../modules/admin-test/insert-dialog/insert-dialog.component'
import {DeleteDialogComponent as DeleteTestDialog} from './../../modules/admin-test/delete-dialog/delete-dialog.component'
import {AdminTestQuestionComponent} from './../../modules/admin-test-question/admin-test-question.component'
import {MatRadioModule} from '@angular/material/radio'
import {AdminTestQuestionAnswerComponent} from './../../modules/admin-test-question-answer/admin-test-question-answer.component'
import {MatSlideToggleModule} from '@angular/material/slide-toggle'
import {DeleteDialogComponent as DeleteAnswerDialog} from './../../modules/admin-test-question-answer/delete-dialog/delete-dialog.component'
import {EditDialogComponent as EditAnswerDialog} from './../../modules/admin-test-question-answer/edit-dialog/edit-dialog.component'
import {InsertDialogComponent as InsertAnswerDialog} from './../../modules/admin-test-question-answer/insert-dialog/insert-dialog.component'
import {AssignTestComponent} from './../../modules/admin-training/assign-test/assign-test.component'
import {AdminLoginComponent} from './../../modules/admin-login/admin-login.component'

import {MatToolbarModule} from '@angular/material/toolbar'
import {MatMenuModule} from '@angular/material/menu'
import {MatListModule} from '@angular/material/list'
import { HighchartsChartModule} from 'highcharts-angular'
import { LayoutModule } from '@angular/cdk/layout';
import { AdminControlComponent } from 'src/app/modules/admin-training-section-detail/admin-control/admin-control.component';
import { MatStepperModule } from '@angular/material/stepper';
import { DeleteControlDialogComponent } from 'src/app/modules/admin-training-section-detail/delete-control-dialog/delete-control-dialog.component';
import { EditControlDialogComponent } from 'src/app/modules/admin-training-section-detail/edit-control-dialog/edit-control-dialog.component';
import { DeleteControlQuestionDialogComponent } from 'src/app/modules/admin-training-section-detail/delete-control-question-dialog/delete-control-question-dialog.component';
import { EditControlQuestionDialogComponent } from 'src/app/modules/admin-training-section-detail/edit-control-question-dialog/edit-control-question-dialog.component';
import { AdminTestQuestionsAndAnswersComponent } from 'src/app/modules/admin-test/admin-test-questions-and-answers/admin-test-questions-and-answers.component';
import { EditTestQuestionComponent } from 'src/app/modules/admin-test/admin-test-questions-and-answers/edit-test-question/edit-test-question.component';
import { DeleteTestQuestionComponent } from 'src/app/modules/admin-test/admin-test-questions-and-answers/delete-test-question/delete-test-question.component';
import { EditTestAnswerComponent } from 'src/app/modules/admin-test/admin-test-questions-and-answers/edit-test-answer/edit-test-answer.component';
import { DeleteTestAnswerComponent } from 'src/app/modules/admin-test/admin-test-questions-and-answers/delete-test-answer/delete-test-answer.component';
import { ForgotPasswordComponent } from 'src/app/modules/admin-login/forgot-password/forgot-password.component';
import {AdminChangePasswordComponent} from 'src/app/modules/admin-change-password/admin-change-password.component';
import { StatusPieComponent } from 'src/app/shared/widgets/status-pie/status-pie.component';
import { TestSuccessPieComponent } from 'src/app/shared/widgets/test-success-pie/test-success-pie.component';
import { PieComponent } from 'src/app/shared/widgets/pie/pie.component';
import { GenerateReportsComponent } from 'src/app/modules/generate-reports/generate-reports.component';
import { WrongLoginDataComponent } from 'src/app/modules/wrong-login-data/wrong-login-data.component';
import { AdminChatComponent } from 'src/app/modules/admin-chat/admin-chat.component';
import { AdminTrainerComponent} from 'src/app/modules/admin-trainer/admin-trainer.component'
import { InsertDialogComponent as InsertTrainerDialogComponent } from 'src/app/modules/admin-trainer/insert-dialog/insert-dialog.component'
import { DeleteDialogComponent as DeleteTrainerDialogComponent } from 'src/app/modules/admin-trainer/delete-dialog/delete-dialog.component'
import { EditDialogComponent as EditTrainerDialogComponent } from 'src/app/modules/admin-trainer/edit-dialog/edit-dialog.component'
import { DeleteImageComponent } from 'src/app/modules/admin-change-password/delete-image/delete-image.component';
import { MatTreeModule } from '@angular/material/tree';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AdminUserSectorComponent } from '../../modules/admin-user-sector/admin-user-sector.component';
import { InsertDialogComponent as insertUserSectorDialog}  from  '../../modules/admin-user-sector/insert-dialog/insert-dialog.component';
import { EditDialogComponent as editUserSectorDialog} from '../../modules/admin-user-sector/edit-dialog/edit-dialog.component';
import { AdminUserProfileComponent } from '../../modules/admin-user-profile/admin-user-profile.component';
import { AddListMessageComponent } from 'src/app/modules/admin-user/add-list-message/add-list-message.component';
import { AdminAboutTrainingComponent } from 'src/app/modules/admin-about-training/admin-about-training.component';
import { AdminAboutSectorsComponent } from 'src/app/modules/admin-about-sectors/admin-about-sectors.component';
import { AdminAboutTestComponent } from 'src/app/modules/admin-about-test/admin-about-test.component';
import {DeleteTrainingDialogComponent} from '../../modules/admin-user-profile/delete-training-dialog/delete-training-dialog.component'
import {MatExpansionModule} from '@angular/material/expansion';
import {DeleteDialogComponent as DeleteUserFromTrainingDialog} from '../../modules/admin-about-training/delete-dialog/delete-dialog.component'
import { UserTrainingsDialogComponent as UserTrainingDialog} from '../../modules/admin-about-training/user-trainings-dialog/user-trainings-dialog.component';
import { SectorTrainingsComponent } from 'src/app/modules/admin-department/sector-trainings/sector-trainings.component';
import { AddPendingUserComponent } from 'src/app/modules/admin-user/add-pending-user/add-pending-user.component';
import { DeleteDialogComponent as DeleteTestForTrainingDialogComponent } from 'src/app/modules/admin-about-test/delete-dialog/delete-dialog.component'
import { AssignTestComponent as AsignTestToTrainingDialogComponent} from 'src/app/modules/admin-about-test/assign-test/assign-test.component'
import { TestQuestionsDialogComponent } from 'src/app/modules/admin-about-test/test-questions-dialog/test-questions-dialog.component'
import {AddQuestionDialogComponent} from 'src/app/modules/admin-about-test/add-question-dialog/add-question-dialog.component'
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { AdminSpinnerComponent } from '../../modules/admin-spinner/admin-spinner.component';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { InsertQuestionDialogComponent } from 'src/app/modules/admin-test-question/insert-question-dialog/insert-question-dialog.component';
import { ControlQuestionComponent } from 'src/app/modules/control-question/control-question.component';
import { ControlQuestionAnswerComponent } from 'src/app/modules/control-question-answer/control-question-answer.component';
import { InsertControlQuestionComponent } from 'src/app/modules/control-question/insert-control-question/insert-control-question.component';
import { InsertControlQuestionAnswerComponent } from 'src/app/modules/control-question/insert-control-question-answer/insert-control-question-answer.component';
import { AdminTrainerProfileComponent } from 'src/app/modules/admin-trainer-profile/admin-trainer-profile.component'
import { AdminUploadDocumentComponent } from 'src/app/modules/admin-upload-document/admin-upload-document.component'
import { DeleteDocumentDialogComponent } from 'src/app/modules/admin-user-profile/delete-document-dialog/delete-document-dialog.component'
import { AdminFaqComponent } from 'src/app/modules/admin-faq/admin-faq.component';
import { AdminAdministratorComponent } from '../../modules/admin-administrator/admin-administrator.component';
import { InsertDialogComponent as InsertAdminDialogComponent } from '../../modules/admin-administrator/insert-dialog/insert-dialog.component';
import { DeleteDialogComponent as DeleteAdminDialogComponent} from '../../modules/admin-administrator/delete-dialog/delete-dialog.component';
import { EditDialogComponent as EditAdminDialogComponent} from '../../modules/admin-administrator/edit-dialog/edit-dialog.component';
import { AssignDocumentsComponent } from '../../modules/admin-about-training/assign-documents/assign-documents.component'
import {AssignTestComponent as AssignSectionTestDialog} from './../../modules/admin-training-detail/assign-test/assign-test.component'
import { DeleteSectionDialogComponent } from '../../modules/admin-about-test/delete-section-dialog/delete-section-dialog.component';
import { ApexChartComponent } from 'src/app/shared/widgets/apex-chart/apex-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { getCustomPaginatorIntl } from '../../../app/modules/customPaginator/customPaginator';
import {MatPaginatorIntl} from '@angular/material/paginator'
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperDialogComponent } from '../../modules/image-cropper-dialog/image-cropper-dialog.component';
import { ProfileImageCropperDialogComponent } from '../../modules/profile-image-cropper-dialog/profile-image-cropper-dialog.component';
import { CopyTrainingComponent } from 'src/app/modules/admin-training/copy-training/copy-training.component';
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule } from 'angular-calendar';
import { AdminCalendarComponent } from 'src/app/modules/admin-calendar/admin-calendar.component';
import { DeleteEventComponent } from 'src/app/modules/admin-calendar/delete-event/delete-event.component';
import { AddEventComponent } from 'src/app/modules/admin-calendar/add-event/add-event.component';
import { EditEventComponent } from 'src/app/modules/admin-calendar/edit-event/edit-event.component';
import { AddMeetingComponent } from 'src/app/modules/admin-zoom-meetings/add-meeting/add-meeting.component';
import { EditMeetingComponent } from 'src/app/modules/admin-zoom-meetings/edit-meeting/edit-meeting.component';
import { DeleteMeetingComponent } from 'src/app/modules/admin-zoom-meetings/delete-meeting/delete-meeting.component';
import { AdminZoomMeetingsComponent } from 'src/app/modules/admin-zoom-meetings/admin-zoom-meetings.component';
import { AdminChatMeetingComponent } from 'src/app/modules/admin-chat/admin-chat-meeting/admin-chat-meeting.component';
import { AdminZoomMeetingComponent } from 'src/app/modules/admin-zoom-meeting/admin-zoom-meeting.component';
import { EvaluationComponentComponent } from 'src/app/modules/admin-zoom-meetings/evaluation-component/evaluation-component.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ImageTrainingCropperDialogComponent } from 'src/app/modules/image-training-cropper-dialog/image-training-cropper-dialog.component'
import {GlobalTrainingsComponent} from '../../modules/global-trainings/global-trainings.component';


@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    LoginComponent,
    AdminUserComponent,
    DeleteDialogComponent,
    EditDialogComponent,
    InsertDialogComponent,
    AdminTrainingComponent,
    UserTrainingsDialogComponent,
    AdminTrainingDetailComponent,
    EditTrainingDialog,
    DeleteTrainingDialog,
    InsertTrainingDialog,
    AdminTrainingSectionDetailComponent,
    InsertTrainingSectionDialog,
    DeleteTrainingSectionDialog,
    EditTrainingSectionDialog,
    InsertSectionUnitDialog,
    DeleteSectionUnitDialog,
    EditSectionUnitDialog,
    AdminDepartmentComponent,
    EditDepartmentDialog,
    InsertDepartmentDialog,
    DeleteDepartmentDialog,
    AdminTestComponent,
    EditTestDialog,
    DeleteTestDialog,
    InsertTestDialog,
    AdminTestQuestionComponent,
    AdminTestQuestionAnswerComponent,
    DeleteAnswerDialog,
    InsertAnswerDialog,
    EditAnswerDialog,
    AssignTestComponent,
    AdminLoginComponent,
    AdminControlComponent,
    DeleteControlDialogComponent,
    EditControlDialogComponent,
    DeleteControlQuestionDialogComponent,
    EditControlQuestionDialogComponent,
    AdminTestQuestionsAndAnswersComponent,
    EditTestQuestionComponent,
    EditTestAnswerComponent,
    DeleteTestQuestionComponent,
    DeleteTestAnswerComponent,
    ForgotPasswordComponent,
    AdminChangePasswordComponent,
    GenerateReportsComponent,
    GlobalTrainingsComponent,
    WrongLoginDataComponent,
    AdminChatComponent,
    AdminTrainerComponent,
    InsertTrainerDialogComponent,
    DeleteTrainerDialogComponent,
    EditTrainerDialogComponent,
    DeleteImageComponent,
    AdminUserSectorComponent,
    insertUserSectorDialog,
    editUserSectorDialog,
    AdminUserProfileComponent,
    AddListMessageComponent,
    AdminAboutTrainingComponent,
    AdminAboutSectorsComponent,
    AdminAboutTestComponent,
    DeleteUserFromTrainingDialog,
    DeleteTrainingDialogComponent,
    UserTrainingDialog,
    SectorTrainingsComponent,
    AddPendingUserComponent,
    DeleteTestForTrainingDialogComponent,
    AsignTestToTrainingDialogComponent,
    TestQuestionsDialogComponent,
    AddQuestionDialogComponent,
    AdminSpinnerComponent,
    InsertQuestionDialogComponent,
    ControlQuestionComponent,
    ControlQuestionAnswerComponent,
    InsertControlQuestionComponent,
    InsertControlQuestionAnswerComponent,
    AdminTrainerProfileComponent,
    AdminUploadDocumentComponent,
    DeleteDocumentDialogComponent,
    AdminFaqComponent,
    AdminAdministratorComponent,
    InsertAdminDialogComponent,
    DeleteAdminDialogComponent,
    EditAdminDialogComponent,
    AssignDocumentsComponent,
    AssignSectionTestDialog,
    DeleteSectionDialogComponent,
    ApexChartComponent,
    ImageCropperDialogComponent,
    ProfileImageCropperDialogComponent,
    CopyTrainingComponent,
    AdminCalendarComponent,
    AddEventComponent,
    EditEventComponent,
    DeleteEventComponent,
    AdminZoomMeetingsComponent,
    AddMeetingComponent,
    EditMeetingComponent,
    DeleteMeetingComponent,
    AdminChatMeetingComponent,
    AdminZoomMeetingComponent,
    EvaluationComponentComponent,
    ImageTrainingCropperDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    FlexLayoutModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatIconModule,
    HttpClientModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSnackBarModule,
    DragDropModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatStepperModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    BrowserAnimationsModule,
    MatTreeModule,
    MatExpansionModule,
    NgxSpinnerModule,
    MatProgressBarModule,
    NgApexchartsModule,
    ImageCropperModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers:[
    DashboardService, { provide: MatPaginatorIntl, useValue: getCustomPaginatorIntl() }
  ],
  exports:[
    RouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DefaultModule { }
