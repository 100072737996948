//switching to development branch
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TrainingSection } from '../models/training-section.model';
import { Training } from '../models/training.model';
import { Unit } from '../models/unit.model';
import { VideoScore } from '../models/videoScore.model';
import { WebRequestService } from '../web-request.service';
import { LocalStorageService } from './services/localStorage-service/local-storage.service';
import { Test } from '../models/test.model';
import { TestQuestion } from '../models/testQuestion.model';
import { TestQuestionAnswer } from '../models/testQuestionAnswer.model'
import { TestQuestionScore } from '../models/testQuestionScore.model';
import { ControlQuestion } from '../models/controlQuestion.model'
import { ControlQuestionAnswers } from '../models/controlQuestionAnswers.model';
import { saveAs } from 'file-saver';
import { UserXTrainings } from '../models/userXTrainings.model'
import { Message } from '../models/message.model'
import { share } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Trainer } from '../models/trainer.model';
import { CalendarEvent } from 'angular-calendar';
import * as schedule from 'node-schedule/index';
import { MatSnackBar } from '@angular/material/snack-bar';

// import * as corsModule from 'cors'
// const cors = corsModule()

const startTime = new Date(Date.now() + 5000);
const endTime = new Date(startTime.getTime() + 10000);

interface Response {
  training: Training
  sections: TrainingSection[]
  units: Unit[]
  questions: ControlQuestion[]
  con_answers: ControlQuestionAnswers[]
}

interface testResponse {
  test: Test
  questions: TestQuestion[]
  answers: TestQuestionAnswer[]
}

interface unitResponse {
  unit: Unit
  question: ControlQuestion
  con_answers: ControlQuestionAnswers[]
}
@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {

  trainer$ = new BehaviorSubject(null)
  training$ = new BehaviorSubject(null)
  sections$ = new BehaviorSubject(null)
  units$ = new BehaviorSubject(null)
  currentUnit$ = new BehaviorSubject(null)
  test$ = new BehaviorSubject(null)
  survey$ = new BehaviorSubject(null)
  pretest$ = new BehaviorSubject(null)
  haveSurvey$ = new BehaviorSubject(null)
  userXTraining$ = new BehaviorSubject(null)
  progress$ = new BehaviorSubject(null) 
  userMessages$ = new BehaviorSubject(null)
  status$ = new BehaviorSubject(null)
  sectionTest$ = new BehaviorSubject(null)
  events$ = new BehaviorSubject(null)
  meetingId$ = new BehaviorSubject(null)
  unitsOrder$ = new BehaviorSubject(null)

  constructor(public db: AngularFirestore, private webRequestService: WebRequestService, private localStorageService: LocalStorageService, private http: HttpClient,
    private snackBar: MatSnackBar) {
   }

  editUserProfile(data) {
    return this.webRequestService.post('users/update', data).subscribe((response) => {
      console.log(response)
    })
  }

   callScheduler(){
    console.log("function called")
    let ref = this.db.firestore.collection("zoomMeetings")
    let docRef = this.db.firestore.collection("zoomMeetings").where("finished", "==", false).orderBy("date", "asc").limit(1)
    docRef.onSnapshot(function(docs) {
      docs.docChanges().forEach(function (doc) {
        console.log("ON SNAPSHOT")
       let date = new Date(doc.doc.data().date.seconds * 1000)
       let day = date.getDate()
       let month = date.getMonth()
       let year = date.getFullYear()
       let hour = date.getHours()
       let min = date.getMinutes()

       let meetingTime = new Date(year, month, day, hour, min, 0)
       let now = new Date()
       let now1 = now.getTime()
       let meetingTimeAfter15 = meetingTime.getTime() + 900000
       let meetingTimeBefore15 = meetingTime.getTime() - 900000
      if((now1 >= meetingTimeBefore15) && (now1 <= meetingTimeAfter15)){
        console.log("meeting time from IF: ", meetingTime)
        console.log(now)
        let start = now.getTime() + 60000
        let startTime = new Date(start)
        schedule.scheduleJob(startTime, function(){
         console.log('Schedule job1');
         console.log(doc.doc.data().id)
         let id = String(doc.doc.data().id)
         ref.doc(id).update({next: true})
       }); 
      }else if((now1 < meetingTimeBefore15) && (now1 <= meetingTimeAfter15)){
        schedule.scheduleJob(meetingTime, function(){
          console.log('Schedule job2');
          console.log(doc.doc.data().id)
          let id = String(doc.doc.data().id)
          ref.doc(id).update({next: true})
        });
      }else{
        ref.doc(doc.doc.data().id).update({finished: true})
      }
      })
    })
  }

  

  setTraining(training: Training) {
    this.localStorageService.setItem("trainingId", training.id)
    this.localStorageService.setItem("testId", training.testId)
    this.localStorageService.setItem("surveyId", training.surveyId)
    this.localStorageService.setItem("pretestId", training.pretestId)
    this.training$.next(this.fetchCurrentTraining(training.id))
  }

  getTraining(): Observable<Training> {
    let trId = this.localStorageService.getItem('trainingId')
    this.training$.pipe(
      switchMap(training => {
        if (training) {
          return of(training)
        }

        const token = this.localStorageService.getItem('token')
        if (token) {
          return this.fetchCurrentTraining(trId)
        }

        return of(null)
      })
    ).subscribe((err) => console.log(err))

    return this.training$
  }

  fetchCurrentTraining(trId): Observable<Training> {
    this.http.get<Response>(this.localStorageService.serversIpAddress + `trainings/${trId}`).subscribe(
      response => {
        let training = response.training
        let sections = response.sections;
        let control_questions = response.questions;

        control_questions.forEach((q) => {
          q.answers = response.con_answers.filter((answer) => answer.questionId == q.id)
          if(q.random){
            q.answers = this.arrayShuffle(q.answers)
          }
          else{
            q.answers = q.answers.sort((a, b) => (a.order < b.order ? -1 : 1));
          }
        })
        
        sections.forEach((s) => {
          let trUnits = response.units.filter((unit) => unit.trainingSectionId == s.id)
          let trainingUnits = []
          trainingUnits = trUnits.sort((a, b) => (a.order < b.order ? -1 : 1));
          s.units = trainingUnits
        })

        let numOfUnits = 0;
        let duration:number = 0;
        sections.forEach((s) => {
          s.units.forEach((u) => {
            u.sectionName = s.name
            if(u.videoDuration){
              let dur = u.videoDuration
              var a = dur.split(':');
              let min = parseInt(a[0])
              let sec = parseInt(a[1])
              let seconds = ((+min * 60) + (+sec*1));
              if(Number.isNaN(seconds) == false){
                duration += seconds
              }
            }
            numOfUnits += 1;
            let niz = control_questions.filter((question) => question.unitId == u.id)
            u.control_question = niz[0]
          })
        })
        
        training.num_of_units = numOfUnits
        training.duration = this.formatTime(duration)
        let trSections = []
        trSections = sections.sort((a, b) => (a.order < b.order ? -1 : 1));
        training.sections = trSections;
        this.training$.next(training);
        return training
      }
    )
    return this.training$
  }

  formatTime(seconds) {
    console.log("duration")
    // var hh = Math.floor(seconds / 3600)
    let h = Math.floor(seconds / 3600)
    let m = Math.floor(seconds / 60) % 60
    let s = Math.floor(seconds) % 60;
    let time = h + "h " +  m + "min"
    console.log(seconds)
    console.log(time)
    return String(time)
  }

  getCurrentUnit(trId): Observable<Unit> {
        const token = this.localStorageService.getItem('token')
        if (token) {
          if (this.localStorageService.getItem('currentUnit') != null) {
            return this.getCurrentUnitFromLocalStorage()
          }
          else {
            return this.fetchCurrentUnit(trId)
          }
        }
        return this.currentUnit$

    
  }

  getCurrentUnitFromLocalStorage(): Observable<Unit> {
    let unit = this.localStorageService.getItem('currentUnit')
    this.currentUnit$.next(unit)
    return this.currentUnit$
  }

  fetchCurrentUnit(trId): Observable<Unit> {
    this.http.get<unitResponse>(this.localStorageService.serversIpAddress + `units/currentUnit/${trId}`).subscribe(
      response => {
        this.currentUnit$.next(response.unit);
        return this.currentUnit$
      }
    )
    return this.currentUnit$;
  }

  updateVideoScore(videoScore: VideoScore) {
    return this.http.post(this.localStorageService.serversIpAddress + 'videoScores/update', videoScore)

  }

  getTestForTraining(testId): Observable<Test> {
        const token = this.localStorageService.getItem('token')
        if (token) {
          if (this.localStorageService.getItem('test') != null) {
            console.log("IF TEST != NULL")
            console.log(this.localStorageService.getItem('test'))
            console.log(testId)
            return this.getTestFromLocalStorage()
          }
          else {
              console.log("IF TEST == NULL")
              console.log(this.localStorageService.getItem('test'))
              console.log(testId)
             return this.fetchTestForTraining(testId)
             
          }
        }
  }
  getTestDetails(testId): Observable<Test> {
        const token = this.localStorageService.getItem('token')
        if (token) {
              console.log("IF TEST == NULL")
              console.log(this.localStorageService.getItem('test'))
              console.log(testId)
             return this.fetchTestForTraining(testId)
        }
  }

  getSectionTestForTraining(testId): Observable<Test> {
    const token = this.localStorageService.getItem('token')
    if (token) {
      if (this.localStorageService.getItem('sectionTest') != null) {
        console.log("IF TEST != NULL")
        console.log(this.localStorageService.getItem('sectionTest'))
        console.log(testId)
        return this.getSectionTestFromLocalStorage()
      }
      else {
          console.log("IF TEST == NULL")
          console.log(testId)
         return this.fetchSectionTestForTraining(testId)
         
      }
    }
}

  getTestForTrainingAdmin(testId): Observable<Test> {
    const token = this.localStorageService.getItem('token')
    if (token) {
         return this.fetchTestForTraining(testId)
    }
}

acceptToS(data) {
  return this.http.post(this.localStorageService.serversIpAddress + 'users/accept-tos', data)
}

  fetchTestForTraining(testId): Observable<Test>{
    console.log("fetch test for training")
    console.log(testId)
    this.http.get<testResponse>(this.localStorageService.serversIpAddress + `tests/testForTraining/${testId}`).subscribe(
      response => {
        console.log("response from fetch training test: ")
        let test = response.test
        console.log("test: ", testId)
        let questions = response.questions;
        questions.forEach((q) => {
          let questionAnswers = response.answers.filter((answer) => answer.testQuestionId == q.id)
          let qAnswers = []
          qAnswers = questionAnswers.sort((a, b) => (a.order < b.order ? -1 : 1));
          if(q.random){
            qAnswers = this.arrayShuffle(qAnswers);
            for (let index = 0; index < qAnswers.length; index++) {
              qAnswers[index].order = index + 1
            }
          }
          q.answers = qAnswers
        })
        questions.forEach((q) => {
          q.answers.forEach((a) => {
            a.selected = false;
          })
        })
        let testQuestions = []
        testQuestions = questions.sort((a, b) => (a.order < b.order ? -1 : 1));
        test.questions = testQuestions;
        if(test.random){
          test.questions = this.arrayShuffle(test.questions);
          for (let index = 0; index < test.questions.length; index++) {
            test.questions[index].order = index + 1
          }
        }
        this.test$.next(test);
        this.localStorageService.setItem('test', test)
        console.log("fetch test for training")
        console.log(this.localStorageService.getItem('test'))
        console.log(test)
        return test
      }
    )
    return this.test$
  }

  fetchSectionTestForTraining(testId): Observable<Test>{
    console.log("fetch section test for training")
    console.log(testId)
    this.http.get<testResponse>(this.localStorageService.serversIpAddress + `tests/testForTraining/${testId}`).subscribe(
      response => {
        console.log("response from fetch training test: ")
        let test = response.test
        console.log("test: ", testId)
        let questions = response.questions;
        questions.forEach((q) => {
          let questionAnswers = response.answers.filter((answer) => answer.testQuestionId == q.id)
          let qAnswers = []
          qAnswers = questionAnswers.sort((a, b) => (a.order < b.order ? -1 : 1));
          if(q.random){
            qAnswers = this.arrayShuffle(qAnswers);
            for (let index = 0; index < qAnswers.length; index++) {
              qAnswers[index].order = index + 1
            }
          }
          q.answers = qAnswers
        })
        questions.forEach((q) => {
          q.answers.forEach((a) => {
            a.selected = false;
          })
        })
        let testQuestions = []
        testQuestions = questions.sort((a, b) => (a.order < b.order ? -1 : 1));
        test.questions = testQuestions;
        if(test.random){
          test.questions = this.arrayShuffle(test.questions);
          for (let index = 0; index < test.questions.length; index++) {
            test.questions[index].order = index + 1
          }
        }
        this.sectionTest$.next(test);
        this.localStorageService.setItem('sectionTest', test)
        console.log("fetch test for training")
        console.log(this.localStorageService.getItem('sectionTest'))
        console.log(test)
        return test
      }
    )
    return this.sectionTest$
  }

  setQuestionScores(questionScores: TestQuestionScore[], survey: boolean, pretest: boolean) {
    const trId = this.localStorageService.getItem('trainingId')
    return this.http.post(this.localStorageService.serversIpAddress + 'test-question-answers/addScores', { scores: questionScores, trainingId: trId, survey: survey, pretest: pretest })
  }
  setQuestionScoresFailed(questionScores: TestQuestionScore[], survey: boolean, pretest: boolean) {
    const trId = this.localStorageService.getItem('trainingId')
    return this.http.post(this.localStorageService.serversIpAddress + 'test-question-answers/addScoresFailed', { scores: questionScores, trainingId: trId, survey: survey, pretest: pretest })
  }
  resetPassword(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/reset-password/', data)
  }
  editUserInfo(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/edit-user-info', data)
  }

  fetchUsersPerDeps() {
    return this.http.get(this.localStorageService.serversIpAddress + 'users/users-in-department')
  }

  fetchUsersByCompany() {
    return this.http.get(this.localStorageService.serversIpAddress + 'users/users-by-company')
  }

  getTestFromLocalStorage(): Observable<Test> {
    let test = this.localStorageService.getItem('test')
    this.test$.next(test)
    return this.test$
  }

  getSectionTestFromLocalStorage(): Observable<Test> {
    let test = this.localStorageService.getItem('sectionTest')
    this.sectionTest$.next(test)
    return this.sectionTest$
  }

  getSurveyForTraining(surveyId): Observable<Test> {

        const token = this.localStorageService.getItem('token')
        if (token) {
          if (this.localStorageService.getItem('survey') != null) {
            console.log("IF SURVEY != NULL")
            console.log(surveyId)
            return this.getSurveyFromLocalStorage()
          }
          else {
            console.log("IF SURVEY == NULL")
            console.log(surveyId)
            return this.fetchSurveyForTraining(surveyId)
          }
        }

    return this.survey$
  }

  fetchSurveyForTraining(surveyId): Observable<Test> {
    console.log("fetch survey for training")
    console.log(surveyId)
    this.http.get<testResponse>(this.localStorageService.serversIpAddress + `tests/testForTraining/${surveyId}`).subscribe(
      response => {
        
    console.log("fetch 1: ")
        let survey = response.test
        let questions = response.questions;
        questions.forEach((q) => {
          let questionAnswers = response.answers.filter((answer) => answer.testQuestionId == q.id)
          let qAnswers = []
          qAnswers = questionAnswers.sort((a, b) => (a.order < b.order ? -1 : 1));
          if(q.random){
            qAnswers = this.arrayShuffle(qAnswers);
            for (let index = 0; index < qAnswers.length; index++) {
              qAnswers[index].order = index + 1
            }
          }
          q.answers = qAnswers
        })
        let testQuestions = []
        testQuestions = questions.sort((a, b) => (a.order < b.order ? -1 : 1));
        survey.questions = testQuestions;
        console.log("fetch 2: ")

        if(survey.random){
          survey.questions = this.arrayShuffle(survey.questions);
          for (let index = 0; index < survey.questions.length; index++) {
            survey.questions[index].order = index + 1
          }
        }
          console.log("fetch 3: ")

        this.survey$.next(survey);
        this.localStorageService.setItem('survey', survey)
        console.log("SURVEY FROM SERVICE")
        console.log(survey)
        return survey
      }
    )
    return this.survey$
  }

  getSurveyFromLocalStorage(): Observable<Test> {
    let survey = this.localStorageService.getItem('survey')
    this.survey$.next(survey)
    return this.survey$
  }

  getPretestForTraining(pretestId): Observable<Test> {
        const token = this.localStorageService.getItem('token')
        if (token) {
          if (this.localStorageService.getItem('pretest') != null) {
            return this.getPretestFromLocalStorage()
          }
          else {
            return this.fetchPretestForTraining(pretestId)
          }
        }

    return this.pretest$
  }

  fetchPretestForTraining(pretestId): Observable<Test> {
    this.http.get<testResponse>(this.localStorageService.serversIpAddress + `tests/testForTraining/${pretestId}`).subscribe(
      response => {
        let pretest = response.test
        let questions = response.questions;
        questions.forEach((q) => {
          let questionAnswers = response.answers.filter((answer) => answer.testQuestionId == q.id)
          let qAnswers = []
          qAnswers = questionAnswers.sort((a, b) => (a.order < b.order ? -1 : 1));
          if(q.random){
            qAnswers = this.arrayShuffle(qAnswers);
            for (let index = 0; index < qAnswers.length; index++) {
              qAnswers[index].order = index + 1
            }
          }
          q.answers = qAnswers
        })
        let testQuestions = []
        testQuestions = questions.sort((a, b) => (a.order < b.order ? -1 : 1));
        pretest.questions = testQuestions;
        console.log("PRETESTQUESTIONS: ", pretest.questions)
        if(pretest.random){
          pretest.questions = this.arrayShuffle(pretest.questions);
          for (let index = 0; index < pretest.questions.length; index++) {
            pretest.questions[index].order = index + 1
          }
        }
        this.pretest$.next(pretest);
        this.localStorageService.setItem('pretest', pretest)
        return pretest
      }
    )
    return this.pretest$
  }

  getPretestFromLocalStorage(): Observable<Test> {
    let pretest = this.localStorageService.getItem('pretest')
    this.pretest$.next(pretest)
    return this.pretest$
  }

  getSurveyFlagForTraining(trainingId: number) {
    this.haveSurvey$.pipe(
      switchMap(haveSurvey => {
        if (haveSurvey) {
          return of(haveSurvey)
        }
        const token = this.localStorageService.getItem('token')
        if (token) {
          if (this.localStorageService.getItem('haveSurvey') != null) {
            return this.getSurveyFlagLocalStorage()
          }
          else {
            return this.fetchSurveyFlagForTraining(trainingId)
          }
        }

        return of(null)
      })
    ).subscribe((err) => console.log(err))

    return this.haveSurvey$
  }

  fetchSurveyFlagForTraining(trainingId: number): Observable<boolean> {
    this.http.get<boolean>(this.localStorageService.serversIpAddress + `users/surveyFlag/${trainingId}`).subscribe(
      response => {
        this.haveSurvey$.next(response);
        this.localStorageService.setItem('surveyFlag', response)

      })
    return this.haveSurvey$
  }


  getSurveyFlagLocalStorage(): Observable<boolean> {
    let flag = this.localStorageService.getItem('surveyFlag')
    this.haveSurvey$.next(flag)
    return flag
  }

  downloadStatement(id, lang, userName) {
    console.log('usao u servis')
    this.http.get(this.localStorageService.serversIpAddress + `units/statement/${id}/${lang}`, { responseType: "blob" }).toPromise()
      .then(blob => {
        saveAs(blob, `statement-${userName}.pdf`);
      })
      .catch(err => console.error("download error = ", err))

  }
  downloadSurveyResults(id, lang) {
    this.http.get(this.localStorageService.serversIpAddress + `units/surveyResults/${id}/${lang}`, { responseType: "blob" }).toPromise()
      .then(blob => {
        saveAs(blob, "surveyResults.pdf");
      })
      .catch(err => console.error("download error = ", err))
  }
  downloadTestResults(id, lang, userName) {
    this.http.get(this.localStorageService.serversIpAddress + `units/testResults/${id}/${lang}`, { responseType: "blob" }).toPromise()
      .then(blob => {
        saveAs(blob, `testResults-${userName}.pdf`);
      })
      .catch(err => console.error("download error = ", err))
  }

  getUserXTrainings(trainingId: string) {
        const token = this.localStorageService.getItem('token')
        if (token) {
          console.log("Get USER X TRAININGS")
          console.log(trainingId)
          return this.fetchUserXTrainings(trainingId)
        }

        return of(null)
  }

  fetchUserXTrainings(id: string): Observable<UserXTrainings> {
    console.log("fetchUserXTrainings")
    this.http.get<UserXTrainings>(this.localStorageService.serversIpAddress + `users/userXTrainings/${id}`).subscribe(
      response => {
        this.userXTraining$.next(response);
        console.log("USER X TRAINING USER DASHBOARD SERVICE")
        console.log(response)
        return response
      })
    return this.userXTraining$
  }
  getTrainerForTraining(trainingId: string) {
    const token = this.localStorageService.getItem('token')
    if (token) {
      return this.fetchTrainerForTraining(trainingId)
    }

    return of(null)
}

fetchTrainerForTraining(id: string): Observable<Trainer> {
this.http.get<Trainer>(this.localStorageService.serversIpAddress + `users/trainerForTraining/${id}`).subscribe(
  response => {
    this.trainer$.next(response);
  })
return this.trainer$
}


  updateNumberOfAttempts(id: string) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/updateNumOfAttempts', { id: id }).subscribe((response) => {
      console.log(response)
    })
  }

  updateNumberOfTestDrops(data){
    return this.http.post(this.localStorageService.serversIpAddress + 'users/updateNumOfTestDrops', data)
  }

  updateVideoScoresForSection(data){
    console.log("UPDATE VIDEO SCORES FO SECTION")
    return this.http.post(this.localStorageService.serversIpAddress + 'units/updateSectionVideoScores', data)
  }

  updateVideoScoresForTraining(data){
    console.log("UPDATE VIDEO SCORES FOR TRAINING")
    return this.http.post(this.localStorageService.serversIpAddress + 'units/updateTrainingVideoScores', data)
  }

  updateNumberOfTestVisits(data){
    return this.http.post(this.localStorageService.serversIpAddress + 'users/updateNumOfTestVisits', data).subscribe((response) => {
      console.log(response)
    })
  }

  changeTrainingStatus(id, status) {
    this.status$.next(status)
    console.log(this.status$)
    return this.http.post(this.localStorageService.serversIpAddress + `users/change-training-status/`, { id: id, status: status })
  }

  testSuccess(data) {
    return this.http.post(this.localStorageService.serversIpAddress + 'users/test-success', data)
  }

  getCurrentUsersProgress(trId): Observable<Number> {
    /* this.progress$.pipe(
      switchMap(scores => {
        // check if we already have user data
        if (scores) {
          console.log('ima progres')
          return of(scores)
        }

        

        return of(null)
      })
    ).subscribe((err) => console.log(err))

    return this.progress$ */
    const token = this.localStorageService.getItem('token')
        // if there is token then fetch the current user scores
        if (token) {
          return this.fetchCurrentUsersProgress(trId)
        }
  }

  fetchCurrentUsersProgress(trId): Observable<Number> {
     this.http.get<VideoScore[]>(this.localStorageService.serversIpAddress + `videoScores/progress/${trId}`)
      .subscribe(
        response => {
          let p = 0
          if(response.length == 0){
          p = 0
          }else{
          let scores = response.filter((s) => s.numberOfVisits > 0)
          let progress = (scores.length/response.length) * 100
          p = Math.floor(progress)
          }
          this.progress$.next(p)
          return this.progress$
        })
        return this.progress$
  }

  getCurrentUnitsOrder(trId): Observable<Number> {
    const token = this.localStorageService.getItem('token')
        if (token) {
          return this.fetchCurrentUnitsOrder(trId)
        }
  }

  fetchCurrentUnitsOrder(trId): Observable<Number> {
     this.http.get<VideoScore[]>(this.localStorageService.serversIpAddress + `videoScores/progress/${trId}`)
      .subscribe(
        response => {
          let length = 0
          if(response.length == 0){
          }else{
          let scores = response.filter((s) => s.numberOfVisits > 0)
          length = scores.length 
          }
          this.unitsOrder$.next(length)
          return this.unitsOrder$
        })
        return this.unitsOrder$
  }

  getTrainingStatus(): Observable<Number> {
    let trId = this.localStorageService.getItem('trainingId')
    console.log('get training: ' + trId);

    const token = this.localStorageService.getItem('token')
        if (token) {
          console.log('ima token 1')
          return this.fetchTrainingStatus(trId)
        }
  }

  fetchTrainingStatus(trId): Observable<Number> {
    this.http.get<Number>(this.localStorageService.serversIpAddress + `trainings/training-status/${trId}`).subscribe(
      response => {
        let trainingStatus = Number(response)
        this.status$.next(trainingStatus);
        console.log("status")
        console.log(trainingStatus)
        console.log(this.status$)
        return trainingStatus
      }
    )
    return this.status$
  }

  arrayShuffle(array: Array<any>) {
    for (let index = array.length - 1; index > 0; index--) {
      const newIndex = Math.floor(Math.random() * (index + 1));
      [array[index], array[newIndex]] = [array[newIndex], array[index]];
    }
    return array
  }

  fetchUsersDocuments(userId){
    return this.http.get(this.localStorageService.serversIpAddress + `users/documents/${userId}`)
  }

  fetchTrainingDocuments(userId, trainingId){
    return this.http.get(this.localStorageService.serversIpAddress + `users/documentUnits/${userId}/${trainingId}`)
  }

  addEvent(data){
    return this.http.post(this.localStorageService.serversIpAddress + `users/addCalendarEvent`, data)
  }

  // getEvents(): Observable<CalendarEvent[]>{
  //   this.http.get(this.localStorageService.serversIpAddress + `users/events`).subscribe((response) => {
  //     this.events$.next(response)
  //     return this.events$
  //   })
  //   return this.events$
  // }

  getEvents(){
    return this.http.get(this.localStorageService.serversIpAddress + `users/events`)
  }

  zoomAccessToken(data){
    console.log(data)
    return this.http.post(this.localStorageService.serversIpAddress + `users/zoom-token`, data)
  }

  getZoomUserDetails(accessToken){
    return this.http.get(this.localStorageService.serversIpAddress + `users/zoom-users-details/${accessToken}`)
  }

  addMeeting(data){
    return this.http.post(this.localStorageService.serversIpAddress + `users/add-zoom-meeting`, data)
  }

  getUserTestNumOfDrops(num){
    return this.http.get(this.localStorageService.serversIpAddress + `users/num-of-drops/${num}`)
  }
  getUserTestDetails(num){
    return this.http.get(this.localStorageService.serversIpAddress + `users/user-training-details/${num}`)
  }

  generateAgoraToken(channelName, uid) {
    return this.http.get(this.localStorageService.serversIpAddress + `tests/agora-token/${channelName}/${uid}`);
  }

  snackMsg(msg) {
    this.snackBar.open(msg, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
  }

  getTermsOfUse(departmentId){
    var tmp = this.http.get(this.localStorageService.serversIpAddress + `users/terms-of-use-url/${departmentId}`)
    console.log("*************************************")
    console.log(tmp)
    console.log("*************************************")
    return tmp;
  }

}