import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../localStorage-service/local-storage.service';
import { UserLanguageService } from '../user-language-service/user-language.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLanguageService {
  lang$ = new BehaviorSubject(null);
  labels$ = new BehaviorSubject(null);
  constructor(private localStorageService: LocalStorageService) { }


  getLanguage(): Observable<String> {
    this.lang$.pipe(
      switchMap(lang => {
        console.log('lang:' + lang)
        if (lang) {
          return of(lang)
        }
        const language = this.localStorageService.getItem('lang')
        console.log('language: ' + language)
        if (language) {
          return language
        }
        return of(null)
      })
    ).subscribe((err) => console.log('error: ' + err))
    return this.lang$
  }

  setLanguage(lang) {
    this.localStorageService.setItem('lang', lang);
    this.lang$.next(lang);
    console.log('novi jezik: ' + this.lang$.value);
    if (this.lang$.value == "srb") {
      this.labels$.next(this.labelsSrb);
    }else if(this.lang$.value == "cirilica"){
      this.labels$.next(this.labelsSrb2);
    }else {
      this.labels$.next(this.labelsEng);
    }
  }

  getLabels() {
    this.lang$.next(this.localStorageService.getItem('lang'))
    if (this.lang$.value == "eng") {
      this.labels$.next(this.labelsEng);
    }else if(this.lang$.value == "cirilica"){
      this.labels$.next(this.labelsSrb2);
    }else {
      this.labels$.next(this.labelsSrb);
    }
    this.labels$.pipe(
      switchMap(labels => {
        console.log('language? ' + labels)
        if (labels) {
          return of(labels)
        }
      })
    ).subscribe((err) => console.log(err))

    return this.labels$
  }

  labelsSrb = {
    lblLanguage: "Jezik",
    lblSerbian: "Srpski",
    lblSerbianCyrillic: "Српски",
    lblEnglish: "English",
    lblLogOut: "Odjavi se",
    lblLogIn: "Uloguj se",
    lblWelcome: "Dobrodošli",
    lblLogInMsg: "Ulogujte se da biste nastavili.",
    lblPassword: "Lozinka",
    lblPassMinCharacters: "(minimalan broj karaktera je 6)",
    lblForgotPass: "Zaboravljena lozinka?",
    lblPassRequired: "Lozinka je obavezna.",
    lblEmailRequired: "Email je obavezan.",
    lblOk: "U redu",
    lblUsers: "Korisnici",
    lblDashBoard: "Početna",
    lblTrainings: "eTreninzi",
    lblTrainingName: "Naziv eTreninga",
    lblSectors: "Sektori/Grupe",
    lblSectorName: "Naziv sektora/grupe",
    lblTests: "Testovi",
    lblAddTraining: "Dodaj novi eTrening",
    lblName: "Ime",
    lblDescription: "Opis",
    lblAddTest: "Dodaj test za eTrening",
    lblSurvey: "Anketa",
    lblPretest: "Pretest",
    lblTest: "Test",
    lblDeleteUsr: "Brisanje eTreninga sa imenom ",
    lblDeleteUser: "Da li ste sigurni da želite da obrišete eTrening?",
    lblDeletePartOfUnit: "Da li ste sigurni da želite da obrišete ovaj deo lekcije?",
    lblDelPartOfUnit: "Brisanje dela lekcije",
    lblNo: "Ne, hvala",
    lblNoStrict: "Ne",
    lblComment: "Komentar",
    lblHelp: "Pomoć",
    lblYes: "Da",
    lblEditTraining: "Izmeni eTrening",
    lblAssignDocuments: "Dodeli dokumenta",
    lblAssignDocumentsToTrainings: "Dodeli dokumenta treningu",
    lblAssign: "Dodeli",
    lblAssignTraining: "Dodeli eTrening",
    lblAssignTests: "Dodeli testove",
    lblEdit: "Izmeni",
    lblAdd: "Dodaj",
    lblDownload: "Preuzmi",
    lblOptions: "Opcije",
    lblAddNewUser: "Dodaj korisnika",
    lblAddList: "Dodaj listu",
    lblUserName: "Korisničko ime",
    lblFirstName: "Ime",
    lblLastName: "Prezime",
    lblAddTrainingToUser: "Dodeli eTrening korisniku ",
    lblAddTrainingToSector: "Dodeli eTrening sektoru ",
    lblSelectADate: "Datum rođenja",
    lblSector: "Sektor",
    lblPosition: "Pozicija",
    lblEditUser: "Izmeni korisnika ",
    lblDelUser: "Brisanje korisnika ",
    lblDelAdmin: "Brisanje administratora",
    lblDeleteThisUser: "Da li ste sigurni da želite da obrišete ovog korisnika?",
    lblDeleteThisAdministrator: "Da li ste sigurni da želite da obrišete ovog administratora?",
    lblAddNewUnit: "Dodaj novu lekciju",
    lblChangeOrder: "Promeni redosled",
    lblSaveOrder: "Sačuvaj redosled",
    lblOrder: "Redosled",
    lblCreate: "Kreiraj",
    lblDelete: "Obriši",
    lblDeleteTestResults: "Obrisi rezultate testa",
    lblRestart: "Restartuj",
    lblAddNewSector: "Dodaj novi sektor/grupu",
    lblParentSector: "Matični sektor",
    lblEditSector: "Izmeni sektor",
    lblDeleteSector: "Brisanje sektora",
    lblDeleteThisSector: "Da li ste sigurni da želite da obrišete ovaj sektor?",
    lblDeleteTest: "Brisanje testa",
    lblDeleteThisTest: "Da li ste sigurni da želite da obrišete ovaj test?",
    lblSections: "Oblasti",
    lblAddNewSection: "Dodaj novu oblast",
    lblUnits: "Lekcije",
    lblDeleteSection: "Brisanje oblasti ",
    lblDeleteThisSection: "Da li ste sigurni da želite da obrišete ovu oblast?",
    lblEditSection: "Izmeni oblast",
    lblControlQuestion: "Kontrolno pitanje",
    lblEditUnit: "Izmeni lekciju",
    lblText: "Tekst",
    lblVideo: "Video",
    lblVideoAudio: "Video/Audio",
    lblVideoAudioMobile: "Video/Аudio (mobilni)",
    lblForMobile: "(mobilni)",
    lblUploadVideo: "Dodaj video",
    lblUploadDoc: "Dodaj dokument",
    lblUploadImage: "Dodaj fotografiju",
    lblUploadCoverImage: "Dodaj naslovnu fotografiju",
    lblUploadVideoHint: "Dodaj pomoćni video",
    lblVideoUnitName: "Naziv video lekcije",
    lblDocumentUnitName: "Naziv dokumenta",
    lblImageUnitName: "Naziv fotografije",
    lblTextUnitName: "Naziv poruke",
    lblVideoUnit: "Video lekcija",
    lblDocUnit: "Dokument",
    lblImageUnit: "Fotografija",
    lblTextUnit: "Poruka",
    lblAudioUnit: "Audio lekcija",
    lblImage: "Fotografija",
    lblDoc: "Dokument",
    lblDeleteUnit: "Brisanje lekcije",
    lblDeleteThisUnit: "Da li ste sigurni da želite da obrišete ovu lekciju?",
    lblNewUser: "Novi korisnik",
    lblOtherInfo: "Ostali podaci",
    lblETrainingName: "Naziv eTreninga",
    lblSectionName: "Naziv oblasti",
    lblGenericWorkplace: "Generičko radno mesto",
    lblRandom: "Nasumično",
    lblNotRandom: "Numerisano",
    lblEnterQuestion: "Unesi pitanje",
    lblEnterAswer: "Unesi odgovor",
    addAnswer: "Dodaj odgovor",
    lblMessageForUnit: "Poruka uz lekciju",
    lblDateCreated: "Datum kreiranja",
    lblDocument: "Dokument",
    //kontrolno
    lblType: "Tip",
    lblOneCorrectAns: "Jedan",
    lblMoreThanOne: "Više",
    lblNext: "Nastavi",
    lblCorrect: "tačno",
    lblNotCorrect: "netačno",
    lblQuestion: "Pitanje",
    lblAnswers: "Odgovori",
    lblAddControlQuestion: "Dodaj kontrolno pitanje",
    lblDeleteAns: "Brisanje odgovora",
    lblDeleteThisAns: "Da li ste sigurni da želite da obrišete odgovor?",
    lblDeleteQuestion: "Brisanje pitanja",
    lblDeleteThisQuestion: "Da li ste sigurni da želite da obrišete pitanje?",
    lblEditQuestion: "Izmena pitanja",
    lblEditControlQuestion: "Izmeni kontrolno pitanje",
    lblEditAnswer: "Izmena odgovora",
    lblFinishedTrainings: "Završeni eTreninzi",
    lblWrongAns: "Pogrešno ste odgovorili na pitanje.",
    lblCorrectAns: "Tačni odgovori: ",
    lblCorrectAnswer: "Bravo! Tačno ste odgovorili na pitanje!",
    lblAdministrator: "Administrator",
    lblAddNewQuestion: "Dodaj pitanje",
    lblTemplate: "Šablon",
    lblDuration: "Trajanje",
    //testovi
    lblNumofAttemptsTest: "Broj pokušaja",
    lblRequiredTest: "Obavezan",
    lblRequiredSurvey: "Obavezna",
    lblMinPoints: "Min. poena",
    lblAddNewTest: "Dodaj novi test/anketu",
    lblQuestions: "Pitanja",
    lblPoints: "Poeni",
    lblPretestName: "Naziv pretesta",
    lblTestName: "Naziv testa",
    lblSurveyName: "Naziv ankete",
    lblTestSurveyName: "Naziv testa/ankete",
    lblName2: "Naziv",
    lblQuestionText: "Tekst pitanja",
    lblAnswerText: "Tekst odgovora",
    lblBack: "Nazad",
    lblUnitName: "Naziv lekcije",
    lblAssignPretest: "Dodeli pretest",
    lblAssignTest: "Dodeli test",
    lblAssignSurvey: "Dodeli anketu",

    //forgot password
    lblEnterEmail: "Ukoliko želite da resetujete lozinku u polje upišite e-mail adresu",
    lblSend: "Pošalji",
    lblSave: "Sačuvaj",
    lblSelectedImage: "Izabrana slika",
    lblWrongLoginData: "Pogrešno ste uneli korisničko ime ili lozinku.",

    //change password
    lblChangePassword: "Izmena lozinke",
    lblOldPassword: "Unesi staru lozinku",
    lblNewPassword: "Unesi novu lozinku",
    lblConfirmPassword: "Potvrdi lozinku",
    btnChange: "Izmeni",
    lblDelPicture: "Obriši profilnu sliku",
    lblDeleteThisUsersProfilePicture: "Da li ste sigurni da želite da obrišete profilnu sliku?",
    lblfieldRequired: "Polje je obavezno.",

    //generate reports
    lblReports: "Izveštaji",
    lblChooseReport: "Izaberite izveštaj koji želite da generišete",
    lblTestResults: "Rezultati testa",
    lblSurveyResults: "Rezultati ankete",
    lblCertificate: "Izjava",
    lblStatement: "Izjava o odslušanom treningu",
    lblGenericWorkPlaceDoc: "Dokument za generičko radno mesto",
    lblAssignedDocuments: "Dodeljena dokumenta ",

    //messages
    lblMessages: "Poruke",
    lblYou: "Vi: ",
    lblWriteAMessage: "Napiši poruku...",

    lblNoTraining: "Nema eTreninga za dodeljivanje.",

    lblGroups: "Grupe",
    lblTrainers: "Treneri",
    lblEtraining: "eTrening kreator",
    lblSettings: "Podešavanja",

    //trainers
    lblAddNewTrainer: "Dodaj novog trenera",
    lblAddTrainer: "Dodaj trenera",
    lblBio: "Biografija",
    lblChooseProfilePicture: "Izaberi profilnu fotografiju: ",
    lblEditTrainer: "Izmeni trenera",
    lblTrainer: "Trener",
    lblDeleteTrainer: "Brisanje trenera",

    lblAudio: "Audio",
    lblAudioUnitName: "Naziv audio lekcije",
    lblUploadAudio: "Dodaj audio",
    lblMinPercentage: "Min. procenat ",
    lblAddListMsg: "Poštovani, Molimo vas da korisnike dodajete u skladu sa uputstvom.",
    lblInstruction: "Uputstvo",

    lblEditSectorGroup: "Izmeni sektor/grupu",
    lblSectorGroup: "Sektor/Grupa",
    lblDueDate: "Rok za polaganje",
    lblCantDeleteAnswer: "Nije moguće obrisati izabrani odgovor",
    lblEmail: "Email",

    lblMoreDetails: "Detaljnije",

    lblBasicInfo: "Osnovni podaci",
    lblPhoneNumber: "Broj telefona",
    lblEditTest: "Izmeni test",
    lblEditPretest: "Izmeni pretest",
    lblEditSurvey: "Izmeni anketu",
    lblO: "R",

    //snackBars
    lblExcelError: "Podaci u excel tabeli nisu pravilno uneti.",
    lblAllfieldsError: "Morate popuniti sva polja kako biste nastavili.",
    lblPasswordFieldError: "Morate pravilno popuniti polja za izmenu lozinke.",
    lblCantDeleteSelectedAnswer: "Nije moguće obrisati izabrani odgovor.",
    lblPasswordError: "Morate pravilno popuniti polja za unos lozinke.",
    lblDataChanged: "Uspešno ste izmenili podatke.",
    lblEmailAlreadyExists: "Korisnik sa unetim e-mailom već postoji.",
    lblUserNameAlreadyExists: "Korisnik sa unetim korisničkim imenom već postoji.",
    lblWeakPassword: "Minimalna duzina lozinke je 6 karaktera",
    lblTextAns: "Tekst",
    lblUserAdded: "Uspešno ste dodali korisnika.",
    lblAdminAdded: "Uspešno ste dodali administratora.",
    lblTrainingAssigned: "Uspešno ste dodelili trening.",
    lblUserDeleted: "Uspešno ste obrisali korisnika.",
    lblAdminDeleted: "Uspešno ste obrisali administratora.",
    lblSectorAdded: "Uspešno ste dodali sektor.",
    lblSectorDeleted: "Uspešno ste obrisali sektor.",
    lblTrainingAdded: "Uspešno ste dodali trening.",
    lblTrainingDeleted: "Uspešno ste obrisali trening.",
    lblTestAssigned: "Uspešno ste dodelili test.",
    lblTrainingSectionAdded: "Uspešno ste dodali oblast.",
    lblTrainingUnitAdded: "Uspešno ste dodali lekciju.",
    lblTrainingSectionDeleted: "Uspešno ste obrisali oblast.",
    lblTrainingSectionEdited: "Uspešno ste izmenili oblast.",
    lblTrainingUnitDeleted: "Uspešno ste obrisali lekciju.",
    lblUserEdited: "Uspešno ste izmenili korisnika.",
    lblAdminEdited: "Uspešno ste izmenili administratora.",
    lblTrainingEdited: "Uspešno ste izmenili trening.",
    lblSectorEdited: "Uspešno ste izmenili sektor.",
    lblTrainingUnitEdited: "Uspešno ste izmenili lekciju.",
    lblControlQuestionAdded: "Uspešno ste dodali kontrolno pitanje.",
    lblControlQuestionDeleted: "Uspešno ste obrisali kontrolno pitanje.",
    lblControlQuestionEdited: "Uspešno ste izmenili kontrolno pitanje.",
    lblControlQuestionAnsAdded: "Uspešno ste dodali odgovor.",
    lblControlQuestionAnsDeleted: "Uspešno ste obrisali odgovor.",
    lblControlQuestionAnsEdited: "Uspešno ste izmenili odgovor.",
    lblQuestionAdded: "Uspešno ste dodali pitanje.",
    lblQuestionDeleted: "Uspešno ste obrisali pitanje.",
    lblQuestionEdited: "Uspešno ste izmenili pitanje.",
    lblTrainerEdited: "Uspešno ste izmenili trenera.",
    lblTrainerDeleted: "Uspešno ste obrisali trenera.",
    lblTrainerInserted: "Uspešno ste dodali trenera.",
    lblTrainingInserted: "Uspešno ste dodali eTrening.",
    lblTestEdited: "Uspešno ste izmenili test.",
    lblTestDeleted: "Uspešno ste obrisali test.",
    lblTestInserted: "Uspešno ste dodali test.",
    lblCropImage: "Isecite sliku",
    lblImageNameError: "Fotografije ne mogu imati isti naziv.",

    lblDeleteThisTrainer: "Da li ste sigurni da želite da obrišete ovog trenera?",

    lblSearch: "Pretraži",
    lblSectorNameAlreadyExists: "Sektor sa unetim nazivom već postoji.",
    lblNumOfUsers: "Broj korisnika",

    lblSection: "Oblast",
    lblUnit: "Lekcija",
    lblPhone: "Broj telefona",
    lblNumOfUnits: "Broj lekcija",
    lblReportType: "Tip izveštaja",
    lblFrom: "od",
    lblTo: "do",
    lblNumOfUsersInSector: "Broj korisnika po sektoru",
    lblNumOfUsersInTraining: "Broj korisnika po eTreningu",
    lblTrainingSuccessRate: "Procenat uspešnosti obuka",
    lblTrainingsStatus: "Status svih obuka",
    lblShowAll: "Prikaži sve",
    lblCantGenerate : "Nije moguće generisati sve izvseštaje",
    lblAddDocument: "Dodaj dokument",
    lblUnsuportedExtension : "Format unetog fajla nije podržan",
    lblDeleteDocument: "Brisanje dokumenta sa imenom ",
    lblDeleteDocumentMessage: "Da li ste sigurni da želite da obrišete ovaj dokument?",
    lblDocumentName: "Naziv dokumenta",
    lblTermsOfUse: "Uslovi korišćenja",
    lblTermsOfUseAdded: "Uspešno ste dodali uslove korišćenja",
    lblAdministrators: "Administratori",
    lblAddAdministrator: "Dodaj administratora",
    lblEditAdministrator: "Izmeni administratora",
    lblTestDuration: "Vreme trajanja (min)",
    lblAnswerExists: "Ovaj odgovor već postoji.",
    
    //Q&A
    lblFAQ: "Najčešće postavljena pitanja",
    q1: "Kako da promenim jezik na platformi?",
    q2: "Šta ako sam zaboravio/la lozinku za pristup platformi?",
    q3: "Kako da kreiram trening na platformi?",
    a1: "Jezik na platformi se menja klikom na zastavu.",
    a2: "Da biste resetovali lozinku za pristup platformi, potrebno je da unesete Vašu mail adresu, i na mail će Vam biti poslata nova lozinka za pristup platformi.",
    a3: "Trening se kreira na platformi u odeljku eTrening kreator.",

    lblAllTrainings: "Sve obuke",
    lblGlobalReport: "Globalni izveštaj",
    lblPriority: "Prioritet",
    lblTimeLimit: "Vremensko ograničenje",
    lblWrongFormat: "Pogrešan format.",
    lblSupportedFormatsImage: "Podržani formati su: jpg, jpeg, png",
    lblSupportedFormatsDocument: "Podržani formati su: docx, pdf i xlsx",
    lblSupportedFormatsVideo: "Podržan format je mp4",
    lblSupportedFormatsAudio: "Podržan format je mp3",
    lblMaxFileSizeImage:"Maksimalna veličina slike je 5MB",
    lblMaxSizeDocument: "Maksimalna veličina dokumenta је 5MB",
    lblMaxSizeVideo: "Maksimalna veličina videa je 250MB",
    lblCopyTraining: "Kopiraj trening",
    lblContinue: "Nastavi",

    //calendar
    lblCalendar: "Kalendar",
    lblPreviousM: "Prethodni",
    lblNextM: "Sledeći",
    lblToday: "Danas",
    lblAddEvent: "Dodaj događaj",
    lblEventTitle: "Naslov događaja",
    lblDate: "Datum",
    lblEditEvent: "Izmeni događaj",
    lblEventEdites: "Uspešno ste izmenili događaj!",
    lblEventDeleted: "Uspešno ste obrisali događaj!",
    lblEventAdded: "Uspešno ste dodali događaj!",
    lblDelEvent: "Obriši događaj",
    lblDeleteThisEvent: "Da li ste sigurni da želite da obrišete događaj?",

    //zoom
    lblMeeting: "Zoom sastanci",
    lblAddMeeting: "Dodaj sastanak",
    lblMeetingTitle: "Naslov sastanka",
    lblEditMeeting: "Izmeni sastanak",
    lblMeetingEdites: "Uspešno ste izmenili sastanak!",
    lblMeetingDeleted: "Uspešno ste obrisali sastanak!",
    lblMeetingAdded: "Uspešno ste dodali sastanak!",
    lblDelMeeting: "Obriši sastanak",
    lblDeleteThisMeeting: "Da li ste sigurni da želite da obrišete sastanak?",
    lblMeetingId: "ID sastanka",
    lblTime: "Vreme",
    lblInMinutes: "u minutima",
    lblHours: "Sat",
    lblMinutes: "Minut",
    lblUserUnavailable: "Korisnik je zauzet.",
    lblCalling: "zove...",
    lblEvaluate: "Oceni",
    lblGrade: "Ocena (1 - 5)",
    lblStart: "Započni",
    lblCantStartMeeting: "Još uvek ne možete započeti sastanak",
  }

  labelsEng = {
    lblNumOfUsers: "Number of users",
    lblSectorNameAlreadyExists: "Sector with entered name already exists.",
    lblLanguage: "Language",
    lblSerbian: "Srpski",
    lblSerbianCyrillic: "Српски",
    lblEnglish: "English",
    lblLogOut: "Log out",
    lblLogIn: "Log in",
    lblWelcome: "Welcome",
    lblLogInMsg: "You need to sign in to continue your request.",
    lblPassword: "Password",
    lblPassMinCharacters: "(min number of characters is 6)",
    lblForgotPass: "Forgot password?",
    lblPassRequired: "Password is required.",
    lblEmailRequired: "Email is required.",
    lblOk: "Okay",
    lblUsers: "Users",
    lblDashBoard: "Dashboard",
    lblTrainings: "eTrainings",
    lblTrainingName: "Training name",
    lblSectors: "Sectors/Groups",
    lblTests: "Tests",
    lblAddTraining: "Add new training",
    lblAssignTraining: "Assign training",
    lblAssignTests: "Assign tests",
    lblName: "Name",
    lblDescription: "Description",
    lblAddTest: "Add test for training",
    lblSurvey: "Survey",
    lblPretest: "Pretest",
    lblTest: "Test",
    lblDeleteUsr: "Delete training with name ",
    lblDeleteUser: "Are you sure you want to delete training?",
    lblDeletePartOfUnit: "Are you sure you want to delete this part of unit?",
    lblDelPartOfUnit: "Delete part of unit",
    lblNo: "No",
    lblNoStrict: "No",
    lblComment: "Comment",
    lblHelp: "Help",
    lblYes: "Yes",
    lblEditTraining: "Edit training",
    lblAssignDocuments: "Assign documents",
    lblAssignDocumentsToTrainings: "Assign documents to training",
    lblAssign: "Assign",
    lblEdit: "Edit",
    lblAdd: "Add",
    lblDownload: "Download",
    lblOptions: "Options",
    lblAddNewUser: "Add user",
    lblAddList: "Add list",
    lblUserName: "Username",
    lblFirstName: "First Name",
    lblLastName: "Last Name",
    lblAddTrainingToUser: "Assign eTraining to user ",
    lblAddTrainingToSector: "Assign eTraining to sector ",
    lblSelectADate: "Date of birth",
    lblSector: "Sector",
    lblPosition: "Position",
    lblEditUser: "Edit user ",
    lblDelUser: "Delete user",
    lblDelAdmin: "Delete administrator",
    lblDeleteThisUser: "Are you sure you want to delete this user?",
    lblDeleteThisAdministrator: "Are you sure you want to delete this administrator?",
    lblAddNewUnit: "Add new unit",
    lblChangeOrder: "Change order",
    lblSaveOrder: "Save this order",
    lblOrder: "Order",
    lblCreate: "Create",
    lblDelete: "Delete",
    lblDeleteTestResults: "Delete test results",
    lblRestart: "Restart",
    lblAddNewSector: "Add new sector/group",
    lblParentSector: "Parent sector",
    lblEditSector: "Edit sector",
    lblDeleteSector: "Delete sector",
    lblDeleteThisSector: "Are you sure you want to delete this sector?",
    lblDeleteTest: "Delete test ",
    lblDeleteThisTest: "Are you sure you want to delete this test?",
    lblSections: "Sections",
    lblAddNewSection: "Add new section",
    lblUnits: "Units",
    lblDeleteSection: "Delete section ",
    lblDeleteThisSection: "Are you sure you want to delete this section?",
    lblEditSection: "Edit section",
    lblControlQuestion: "Control question",
    lblEditUnit: "Izmenite lekciju",
    lblText: "Text",
    lblVideo: "Video",
    lblVideoAudio: "Video/Audio",
    lblVideoAudioMobile: "Video/Аudio (mobile)",
    lblForMobile: "(mobile)",
    lblUploadVideo: "Upload video",
    lblUploadDoc: "Upload document",
    lblUploadImage: "Upload image",
    lblUploadCoverImage: "Upload cover image",
    lblUploadVideoHint: "Upload hint video",
    lblVideoUnitName: "Video unit name",
    lblDocumentUnitName: "Document unit name",
    lblImageUnitName: "Image name",
    lblTextUnitName: "Text unit name",
    lblVideoUnit: "Video unit",
    lblAudioUnit: "Audio unit",
    lblDocUnit: "Document",
    lblImageUnit: "Image",
    lblTextUnit: "Message",
    lblImage: "Image",
    lblDoc: "Document",
    lblDeleteUnit: "Delete unit",
    lblDeleteThisUnit: "Are you sure you want to delete this unit?",
    lblFinishedTrainings: "Finished trainings",
    lblNewUser: "New user",
    lblOtherInfo: "Other info",
    lblSectorName: "Sector/Group name",
    lblETrainingName: "eTraining name",
    lblSectionName: "Section name",
    lblGenericWorkplace: "Generic workplace",
    lblRandom: "Random",
    lblNotRandom: "Ordered",
    lblEnterQuestion: "Enter question",
    lblEnterAswer: "Enter answer",
    lblAdministrator: "Administrator",
    lblAddNewQuestion: "Add question",
    lblTemplate: "Template",
    addAnswer: "Add new answer",
    lblMessageForUnit: "Message for unit",
    lblDateCreated: "Date created",
    lblDocument: "Document",

    //kontrolno
    lblType: "Type",
    lblOneCorrectAns: "One",
    lblMoreThanOne: "More than one",
    lblNext: "Next",
    lblCorrect: "correct",
    lblNotCorrect: "not correct",
    lblQuestion: "Question",
    lblAnswers: "Answers",
    lblAddControlQuestion: "Add control question",
    lblDeleteAns: "Delete Answer",
    lblDeleteThisAns: "Are you sure you want to delete this answer?",
    lblDeleteQuestion: "Delete question",
    lblDeleteThisQuestion: "Are you sure you want to delete this question?",
    lblEditQuestion: "Edit question",
    lblEditControlQuestion: "Edit control question",
    lblEditAnswer: "Edit answer",
    lblWrongAns: "You didn't answer the question correctly.",
    lblCorrectAns: "Correct answers are: ",
    lblCorrectAnswer: "Well done! You answered the question correctly.",
    lblDuration: "Duration",

    //testovi
    lblNumofAttemptsTest: "Number of attempts",
    lblRequiredTest: "Required",
    lblRequiredSurvey: "Required",
    lblMinPoints: "Min. points",
    lblAddNewTest: "Add new test/survey",
    lblQuestions: "Questions",
    lblPoints: "Points",
    lblPretestName: "Pretest name",
    lblTestName: "Test name",
    lblSurveyName: "Survey name",
    lblName2: "Name",
    lblQuestionText: "Question text",
    lblAnswerText: "Answer text",
    lblBack: "Back",
    lblUnitName: "Unit name",
    lblTestSurveyName: "Test/survey name",
    lblAssignPretest: "Assign pretest",
    lblAssignTest: "Assign test",
    lblAssignSurvey: "Assign survey",

    //forgot password
    lblEnterEmail: "If you want to reset your password, enter your e-mail address in the field",
    lblSend: "Send",

    lblWrongLoginData: "You entered your username or password incorrectly",

    //change password
    lblChangePassword: "Change password",
    lblOldPassword: "Enter old password",
    lblNewPassword: "Enter new password",
    lblConfirmPassword: "Confirm password",
    btnChange: "Change",
    lblSave: "Save",
    lblSelectedImage: "Selected image",
    lblDelPicture: "Delete profile picture",
    lblDeleteThisUsersProfilePicture: "Are you sure you want to delete profile picture?",

    lblfieldRequired: "Field is required.",

    //generate reports
    lblReports: "Reports",
    lblChooseReport: "Select the report you want to generate",
    lblTestResults: "Test results",
    lblSurveyResults: "Survey results",
    lblCertificate: "Statement",
    lblStatement: "Statement",
    lblGenericWorkPlaceDoc: "Generic work place document",
    lblAssignedDocuments: "Assigned documents ",

    //messages
    lblMessages: "Messages",
    lblYou: "You: ",
    lblWriteAMessage: "Write a message...",

    lblNoTraining: "There is no training to be assigned.",

    lblGroups: "Groups",
    lblTrainers: "Trainers",
    lblEtraining: "eTraining creator",
    lblSettings: "Settings",

    //trainers
    lblAddNewTrainer: "Add new Trainer",
    lblAddTrainer: "Add trainer",
    lblBio: "Biography",
    lblChooseProfilePicture: "Choose profile picture: ",
    lblEditTrainer: "Edit trainer",
    lblTrainer: "Trainer",
    lblDeleteTrainer: "Delete trainer",

    lblAudio: "Audio",
    lblAudioUnitName: "Audio unit name",
    lblUploadAudio: "Upload video",
    lblMinPercentage: "Min. percentage",

    lblAddListMsg: "Please add users according to the instructions",
    lblInstruction: "Instructions",

    lblEditSectorGroup: "Edit sector/group",
    lblSectorGroup: "Sector/Group",
    lblDueDate: "Due date",
    lblCantDeleteAnswer: "Can't delete selected answer",
    lblEmail: "Email",

    lblMoreDetails: "More details",

    lblBasicInfo: "Basic info",
    lblPhoneNumber: "Phone number",
    lblEditTest: "Edit test",
    lblEditPretest: "Edit pretest",
    lblEditSurvey: "Edit survey",
    lblO: "O",

    //snackBars
    lblExcelError: "Data in excel table is not inserted correctly.",
    lblAllfieldsError: "You must fill all fields in order to continue.",
    lblPasswordFieldError: "Fields for password change must be filled correctly.",
    lblCantDeleteSelectedAnswer: "Cant delete selected answer.",
    lblPasswordError: "Fields for password input must be filled correctly.",
    lblDataChanged: "You have successfully changed the data.",
    lblEmailAlreadyExists: "User with entered email already exists.",
    lblUserNameAlreadyExists: "User with entered username already exists.",
    lblWeakPassword: "Password must be at least 6 characters long",
    lblTextAns: "Text",
    lblUserAdded: "User added successfully.",
    lblAdminAdded: "Admin added successfully.",
    lblTrainingAssigned: "Training added successfully.",
    lblUserDeleted: "User deleted successfully.",
    lblAdminDeleted: "Administrator deleted successfully.",
    lblSectorAdded: "Sector added successfully.",
    lblSectorDeleted: "Sector deleted successfully.",
    lblTrainingAdded: "Training added successfully.",
    lblTrainingDeleted: "Training deleted successfully.",
    lblTestAssigned: "Test assigned successfully.",
    lblTrainingSectionAdded: "Section added successfully.",
    lblTrainingUnitAdded: "Unit added successfully.",
    lblTrainingSectionDeleted: "Section deleted successfully.",
    lblTrainingSectionEdited: "Section edited successfully.",
    lblTrainingUnitDeleted: "Unit deleted successfully.",
    lblUserEdited: "User edited successfully.",
    lblAdminEdited: "Administrator edited successfully.",
    lblTrainingEdited: "Training edited successfully.",
    lblSectorEdited: "Sector edited successfully.",
    lblTrainingUnitEdited: "Unit edited successfully.",
    lblControlQuestionAdded: "Control question added successfully.",
    lblControlQuestionDeleted: "Control question deleted successfully",
    lblControlQuestionEdited: "Control question edited successfully.",
    lblControlQuestionAnsAdded: "Answer added successfully.",
    lblControlQuestionAnsDeleted: "Answer deleted successfully.",
    lblControlQuestionAnsEdited: "Answer edited successfully.",
    lblQuestionAdded: "Question added successfully.",
    lblQuestionDeleted: "Question deleted successfully.",
    lblQuestionEdited: "Question edited successfully.",
    lblTrainerEdited: "Trainer edited successfully.",
    lblTrainerDeleted: "Trainer deleted successfully.",
    lblTrainerInserted: "Trainer added successfully.",
    lblTrainingInserted: "Training added successfully.",
    lblTestEdited: "Test edited successfully.",
    lblTestDeleted: "Test deleted successfully.",
    lblTestInserted: "Test added successfully.",
    lblCropImage: "Crop image",
    lblImageNameError: "Images can't have the same title.",

    lblDeleteThisTrainer: "Are you sure you want to delete this trainer?",

    lblSearch: "Search",

    lblSection: "Section",
    lblUnit: "Unit",

    lblPhone: "Phone number",
    lblNumOfUnits: "Number of units",
    lblReportType: "Report type",
    lblFrom: "from",
    lblTo: "to",
    lblNumOfUsersInSector: "Number of users per sector",
    lblNumOfUsersInTraining: "Number of users per training",
    lblTrainingSuccessRate: "Training success rate",
    lblTrainingsStatus: "Status of trainings",
    lblShowAll: "Show all",
    lblCantGenerate : "Cant generate all reports",
    lblAddDocument: "Add a document",
    lblUnsuportedExtension : "Format of the file is not supported",
    lblDeleteDocument: "Delete document with name ",
    lblDeleteDocumentMessage: "Are you sure you want to delete this document?",
    lblDocumentName: "Document name",
    lblTermsOfUse: "Terms of use",
    lblTermsOfUseAdded: "Terms of use successfully added.",
    lblAdministrators: "Administrators",
    lblAddAdministrator: "Add administrator",
    lblEditAdministrator: "Edit administrator",
    lblTestDuration: "Duration time (min)",
    lblAnswerExists: "Answer already exists.",
    lblContinue: "Continue",

    lblFAQ: "Frequently asked question",
    q1: "How do I change the language on the platform?",
    q2: "What if I forgot my platform access password?",
    q3: "How do I create platform training?",
    a1: "The language on the platform is changed by clicking on the flag.",
    a2: "To reset the password, you need to enter your e-mail address, and a new password will be sent to you by e-mail.",
    a3: "Training is created in the eTraining creator section.",

    lblAllTrainings: "All trainings",
    lblGlobalReport: "Global report",
    lblPriority: "Priority",
    lblTimeLimit: "Time limit",
    lblWrongFormat: "Wrong format.",
    lblSupportedFormatsImage: "Supported formats are: jpg, jpeg, png",
    lblSupportedFormatsDocument: "Supported formats are: docx, pdf and xlsx",
    lblSupportedFormatsVideo: "Supported format is mp4",
    lblSupportedFormatsAudio: "Supported format is mp3",
    lblMaxFileSizeImage:"Max image size is 5MB",
    lblMaxSizeDocument: "Max document size is 5MB",
    lblMaxSizeVideo: "Max video size is 50MB",
    lblCopyTraining: "Copy training",

    lblCalendar: "Calendar",
    lblPreviousM: "Previous",
    lblNextM: "Next",
    lblToday: "Today",
    lblAddEvent: "Add event",
    lblEventTitle: "Event title",
    lblDate: "Date",
    lblEditEvent: "Edit event",
    lblEventEdites: "Event edited successfully!",
    lblEventDeleted: "Event deleted successfully!",
    lblEventAdded: "Event added successfully!",
    lblDelEvent: "Delete event",
    lblDeleteThisEvent: "Are you sure you want to delete this event?",

    //zoom
    lblMeeting: "Zoom sastanci",
    lblAddMeeting: "Add meeting",
    lblMeetingTitle: "Meeting title",
    lblEditMeeting: "Edit meeting",
    lblMeetingEdites: "Meeting edited successfully!",
    lblMeetingDeleted: "Meeting deleted successfully!",
    lblMeetingAdded: "Meeting added successfully!",
    lblDelMeeting: "Delete meeting",
    lblDeleteThisMeeting: "Are you sure you want to delete this meeting?",
    lblMeetingId: "Meeting ID",
    lblTime: "Time",
    lblInMinutes: "in minutes",
    lblHours: "Hours",
    lblMinutes: "Minutes",
    lblUserUnavailable: "User is unavailable.",
    lblCalling: "calling...",
    lblEvaluate: "Evaluate",
    lblGrade: "Grade",
    lblStart: "Start",
    lblCantStartMeeting: "You still can't start a meeting."
  }

  labelsSrb2 = {
    lblGlobalReport: "Глобални извештај",
    lblFAQ: "Најчешће постављена питања",
    q1: "Како да променим језик на платформи?",
    q2: "Шта ако сам заборавио/ла лозинку за приступ платформи?",
    q3: "Како да креирам тренинг на платформи?",
    a1: "Језик на платформи се мења кликом на заставу.",
    a2: "Да бисте ресетовали лозинку за приступ платформи, потребно је да унесете Вашу маил адресу, и на маил ће Вам бити послата нова лозинка за приступ платформи.",
    a3: "Тренинг се креира на платформи у одељку еТренинг креатор.",
    lblLanguage: "Језик",
    lblSerbian: "Srpski",
    lblEnglish: "English",
    lblSerbianCyrillic: "Српски",
    lblLogOut: "Одјави се",
    lblLogIn: "Улогуј се",
    lblWelcome: "Добродошли",
    lblLogInMsg: "Улогујте се да бисте наставили.",
    lblPassword: "Лозинка",
    lblPassMinCharacters: "(минималан број карактера је 6)",
    lblForgotPass: "Заборављена лозинка?",
    lblPassRequired: "Лозинка је обавезна.",
    lblEmailRequired: "Емаил је обавезан.",
    lblOk: "У реду",
    lblUsers: "Корисници",
    lblDashBoard: "Почетна",
    lblTrainings: "еТренинзи",
    lblTrainingName: "Назив еТренинга",
    lblSectors: "Сектори/Групе",
    lblSectorName: "Назив сектора/групе",
    lblTests: "Тестови",
    lblAddTraining: "Додај нови еТренинг",
    lblName: "Име",
    lblDescription: "Опис",
    lblAddTest: "Додај тест за еТренинг",
    lblSurvey: "Анкета",
    lblPretest: "Претест",
    lblTest: "Тест",
    lblDeleteUsr: "Брисање еТренинга са именом ",
    lblDeleteUser: "Да ли сте сигурни да желите да обришете еТренинг?",
    lblDeletePartOfUnit: "Да ли сте сигурни да желите да обришете овај део лекције?",
    lblDelPartOfUnit: "Брисање дела лекције",
    lblNo: "Не , хвала",
    lblNoStrict: "Не",
    lblComment: "Коментар",
    lblHelp: "Помоћ",
    lblYes: "Да",
    lblEditTraining: "Измени еТренинг",
    lblAssignDocuments: "Додели документа",
    lblAssignDocumentsToTrainings: "Додели документа тренингу",
    lblAssign: "Додели",
    lblAssignTraining: "Додели еТренинг",
    lblAssignTests: "Додели тестове",
    lblEdit: "Измени",
    lblAdd: "Додај",
    lblDownload: "Преузми",
    lblOptions: "Опције",
    lblAddNewUser: "Додај корисника",
    lblAddList: "Додај листу",
    lblUserName: "Корисничко име",
    lblFirstName: "Име",
    lblLastName: "Презиме",
    lblAddTrainingToUser: "Додели еТренинг кориснику ",
    lblAddTrainingToSector: "Додели еТренинг сектору ",
    lblSelectADate: "Датум рођења",
    lblSector: "Сектор",
    lblPosition: "Позиција",
    lblEditUser: "Измени корисника ",
    lblDelUser: "Брисање корисника ",
    lblDelAdmin: "Брисање администратора",
    lblDeleteThisUser: "Да ли сте сигурни да желите да обришете овог корисника?",
    lblDeleteThisAdministrator: "Да ли сте сигурни да желите да обришете овог администратора?",
    lblAddNewUnit: "Додај нову лекцију",
    lblChangeOrder: "Промени редослед",
    lblSaveOrder: "Сачувај редослед",
    lblOrder: "Редослед",
    lblCreate: "Креирај",
    lblDelete: "Обриши",
    lblDeleteTestResults: "Обриши резултате теста",
    lblRestart: "Рестартуј",
    lblAddNewSector: "Додај нови сектор/групу",
    lblParentSector: "Матични сектор",
    lblEditSector: "Измени сектор",
    lblDeleteSector: "Брисање сектора",
    lblDeleteThisSector: "Да ли сте сигурни да желите да обришете овај сектор?",
    lblDeleteTest: "Брисање теста",
    lblDeleteThisTest: "Да ли сте сигурни да желите да обришете овај тест?",
    lblSections: "Области",
    lblAddNewSection: "Додај нову област",
    lblUnits: "Лекције",
    lblDeleteSection: "Брисање области ",
    lblDeleteThisSection: "Да ли сте сигурни да желите да обришете ову област?",
    lblEditSection: "Измени област",
    lblControlQuestion: "Контролно питање",
    lblEditUnit: "Измени лекцију",
    lblText: "Текст",
    lblContinue: "Настави",
    lblVideo: "Видео",
    lblVideoAudio: "Видео/Aудио",
    lblVideoAudioMobile: "Видео/Aудио (мобилни)",
    lblForMobile: "(мобилни)",
    lblUploadVideo: "Додај видео",
    lblUploadDoc: "Додај документ",
    lblUploadImage: "Додај фотографију",
    lblUploadCoverImage: "Додај насловну фотографију",
    lblUploadVideoHint: "Додај помоћни видео",
    lblVideoUnitName: "Назив видео лекције",
    lblDocumentUnitName: "Назив документа",
    lblImageUnitName: "Назив фотографије",
    lblTextUnitName: "Назив поруке",
    lblVideoUnit: "Видео лекција",
    lblDocUnit: "Документ",
    lblImageUnit: "Фотографија",
    lblTextUnit: "Порука",
    lblAudioUnit: "Аудио лекција",
    lblImage: "Фотографија",
    lblDoc: "Документ",
    lblDeleteUnit: "Брисање лекције",
    lblDeleteThisUnit: "Да ли сте сигурни да желите да обришете ову лекцију?",
    lblNewUser: "Нови корисник",
    lblOtherInfo: "Остали подаци",
    lblETrainingName: "Назив еТренинга",
    lblSectionName: "Назив области",
    lblGenericWorkplace: "Генеричко радно место",
    lblRandom: "Насумично",
    lblNotRandom: "Нумерисано",
    lblEnterQuestion: "Унеси питање",
    lblEnterAswer: "Унеси одговор",
    addAnswer: "Додај одговор",
    lblMessageForUnit: "Порука уз лекцију",
    lblDateCreated: "Датум креирања",
    lblDocument: "Документ",
    lblType: "Тип",
    lblOneCorrectAns: "Један",
    lblMoreThanOne: "Више",
    lblNext: "Настави",
    lblCorrect: "тачно",
    lblNotCorrect: "нетачно",
    lblQuestion: "Питање",
    lblAnswers: "Одговори",
    lblAddControlQuestion: "Додај контролно питање",
    lblDeleteAns: "Брисање одговора",
    lblDeleteThisAns: "Да ли сте сигурни да желите да обришете одговор?",
    lblDeleteQuestion: "Брисање питања",
    lblDeleteThisQuestion: "Да ли сте сигурни да желите да обришете питање?",
    lblEditQuestion: "Измена питања",
    lblEditControlQuestion: "Измени контролно питање",
    lblEditAnswer: "Измена одговора",
    lblFinishedTrainings: "Завршени еТренинзи",
    lblWrongAns: "Погрешно сте одговорили на питање.",
    lblCorrectAns: "Тачни одговори: ",
    lblCorrectAnswer: "Браво! Тачно сте одговорили на питање!",
    lblAdministrator: "Администратор",
    lblAddNewQuestion: "Додај питање",
    lblTemplate: "Шаблон",
    lblDuration: "Трајање",
    //testovi
    lblNumofAttemptsTest: "Број покушаја",
    lblRequiredTest: "Обавезан",
    lblRequiredSurvey: "Обавезна",
    lblMinPoints: "Мин . поена",
    lblAddNewTest: "Додај нови тест/анкету",
    lblQuestions: "Питања",
    lblPoints: "Поени",
    lblPretestName: "Назив претеста",
    lblTestName: "Назив теста",
    lblSurveyName: "Назив анкете",
    lblTestSurveyName: "Назив теста/анкете",
    lblName2: "Назив",
    lblQuestionText: "Текст питања",
    lblAnswerText: "Текст одговора",
    lblBack: "Назад",
    lblUnitName: "Назив лекције",
    lblAssignPretest: "Додели претест",
    lblAssignTest: "Додели тест",
    lblAssignSurvey: "Додели анкету",
    lblEnterEmail: "Уколико желите да ресетујете лозинку у поље упишите е-маил адресу",
    lblSend: "Пошаљи",
    lblSave: "Сачувај",
    lblSelectedImage: "Изабрана слика",
    lblWrongLoginData: "Погрешно сте унели корисничко име или лозинку.",
    lblChangePassword: "Измена лозинке",
    lblOldPassword: "Унеси стару лозинку",
    lblNewPassword: "Унеси нову лозинку",
    lblConfirmPassword: "Потврди лозинку",
    btnChange: "Измени",
    lblDelPicture: "Обриши профилну слику",
    lblDeleteThisUsersProfilePicture: "Да ли сте сигурни да желите да обришете профилну слику?",
    lblfieldRequired: "Поље је обавезно.",
    lblReports: "Извештаји",
    lblChooseReport: "Изаберите извештај који желите да генеришете",
    lblTestResults: "Резултати теста",
    lblSurveyResults: "Резултати анкете",
    lblCertificate: "Изјава",
    lblStatement: "Изјава о одслушаном тренингу",
    lblGenericWorkPlaceDoc: "Документ за генеричко радно место",
    lblAssignedDocuments: "Додељена документа ",
    lblMessages: "Поруке",
    lblYou: "Ви: ",
    lblWriteAMessage: "Напиши поруку...",
    lblNoTraining: "Нема еТренинга за додељивање.",
    lblGroups: "Групе",
    lblTrainers: "Тренери",
    lblEtraining: "еТренинг креатор",
    lblSettings: "Подешавања",
    lblAddNewTrainer: "Додај новог тренера",
    lblAddTrainer: "Додај тренера",
    lblBio: "Биографија",
    lblChooseProfilePicture: "Изабери профилну фотографију: ",
    lblEditTrainer: "Измени тренера",
    lblTrainer: "Тренер",
    lblDeleteTrainer: "Брисање тренера",
    lblAudio: "Аудио",
    lblAudioUnitName: "Назив аудио лекције",
    lblUploadAudio: "Додај аудио",
    lblMinPercentage: "Мин . проценат ",
    lblAddListMsg: "Поштовани , Молимо вас да кориснике додајете у складу са упутством.",
    lblInstruction: "Упутство",
    lblEditSectorGroup: "Измени сектор/групу",
    lblSectorGroup: "Сектор/Групa",
    lblDueDate: "Рок за полагање",
    lblCantDeleteAnswer: "Није могуће обрисати изабрани одговор",
    lblEmail: "Емаил",
    lblMoreDetails: "Детаљније",
    lblBasicInfo: "Основни подаци",
    lblPhoneNumber: "Број телефона",
    lblEditTest: "Измени тест",
    lblEditPretest: "Измени претест",
    lblEditSurvey: "Измени анкету",
    lblO: "Р",
    lblExcelError: "Подаци у excel табели нису правилно унети.",
    lblAllfieldsError: "Морате попунити сва поља како бисте наставили.",
    lblPasswordFieldError: "Морате правилно попунити поља за измену лозинке.",
    lblCantDeleteSelectedAnswer: "Није могуће обрисати изабрани одговор.",
    lblPasswordError: "Морате правилно попунити поља за унос лозинке.",
    lblDataChanged: "Успешно сте изменили податке.",
    lblEmailAlreadyExists: "Корисник са унетим е-маилом већ постоји.",
    lblUserNameAlreadyExists: "Корисник са унетим корисничким именом већ постоји.",
    lblWeakPassword: "Минимална дузина лозинке је 6 карактера",
    lblTextAns: "Текст",
    lblUserAdded: "Успешно сте додали корисника.",
    lblAdminAdded: "Успешно сте додали администратора.",
    lblTrainingAssigned: "Успешно сте доделили тренинг.",
    lblUserDeleted: "Успешно сте обрисали корисника.",
    lblAdminDeleted: "Успешно сте обрисали администратора.",
    lblSectorAdded: "Успешно сте додали сектор.",
    lblSectorDeleted: "Успешно сте обрисали сектор.",
    lblTrainingAdded: "Успешно сте додали тренинг.",
    lblTrainingDeleted: "Успешно сте обрисали тренинг.",
    lblTestAssigned: "Успешно сте доделили тест.",
    lblTrainingSectionAdded: "Успешно сте додали област.",
    lblTrainingUnitAdded: "Успешно сте додали лекцију.",
    lblTrainingSectionDeleted: "Успешно сте обрисали област.",
    lblTrainingSectionEdited: "Успешно сте изменили област.",
    lblTrainingUnitDeleted: "Успешно сте обрисали лекцију.",
    lblUserEdited: "Успешно сте изменили корисника.",
    lblAdminEdited: "Успешно сте изменили администратора.",
    lblTrainingEdited: "Успешно сте изменили тренинг.",
    lblSectorEdited: "Успешно сте изменили сектор.",
    lblTrainingUnitEdited: "Успешно сте изменили лекцију.",
    lblControlQuestionAdded: "Успешно сте додали контролно питање.",
    lblControlQuestionDeleted: "Успешно сте обрисали контролно питање.",
    lblControlQuestionEdited: "Успешно сте изменили контролно питање.",
    lblControlQuestionAnsAdded: "Успешно сте додали одговор.",
    lblControlQuestionAnsDeleted: "Успешно сте обрисали одговор.",
    lblControlQuestionAnsEdited: "Успешно сте изменили одговор.",
    lblQuestionAdded: "Успешно сте додали питање.",
    lblQuestionDeleted: "Успешно сте обрисали питање.",
    lblQuestionEdited: "Успешно сте изменили питање.",
    lblTrainerEdited: "Успешно сте изменили тренера.",
    lblTrainerDeleted: "Успешно сте обрисали тренера.",
    lblTrainerInserted: "Успешно сте додали тренера.",
    lblTrainingInserted: "Успешно сте додали еТренинг.",
    lblTestEdited: "Успешно сте изменили тест.",
    lblTestDeleted: "Успешно сте обрисали тест.",
    lblTestInserted: "Успешно сте додали тест.",
    lblCropImage: "Исеците слику",
    lblImageNameError: "Фотографије не могу имати исти назив.",

    lblDeleteThisTrainer: "Да ли сте сигурни да желите да обришете овог тренера?",

    lblSearch: "Претражи",
    lblSectorNameAlreadyExists: "Сектор са унетим називом већ постоји.",
    lblNumOfUsers: "Број корисника",

    lblSection: "Област",
    lblUnit: "Лекција",
    lblPhone: "Број телефона",
    lblNumOfUnits: "Број лекција",
    lblReportType: "Тип извештаја",
    lblFrom: "од",
    lblTo: "до",
    lblNumOfUsersInSector: "Број корисника по сектору",
    lblNumOfUsersInTraining: "Број корисника по тренингу",
    lblTrainingSuccessRate: "Проценат успешности обука",
    lblTrainingsStatus: "Статус свих обука",
    lblShowAll: "Прикажи све",
    lblCantGenerate : "Није могуће генерисати све извсештаје",
    lblAddDocument: "Додај документ",
    lblUnsuportedExtension : "Формат унетог фајла није подржан",
    lblDeleteDocument: "Брисање документа са именом ",
    lblDeleteDocumentMessage: "Да ли сте сигурни да желите да обришете овај документ?",
    lblDocumentName: "Назив документа",
    lblTermsOfUse: "Услови коришћења",
    lblTermsOfUseAdded: "Успешно сте додали услове коришћења",
    lblAdministrators: "Администратори",
    lblAddAdministrator: "Додај администратора",
    lblEditAdministrator: "Измени администратора",
    lblTestDuration: "Време трајања (мин)",
    lblAnswerExists: "Овај одговор већ постоји.",

    lblAllTrainings: "Све обуке",
    lblPriority: "Приоритет",
    lblTimeLimit: "Временско ограничење",
    lblWrongFormat: "Погрешан формат.",
    lblSupportedFormatsImage: "Подржани формати су: jpg, jpeg, png",
    lblSupportedFormatsDocument: "Подржани формати су: docx, pdf и xlsx",
    lblSupportedFormatsVideo: "Подржан формат je mp4",
    lblSupportedFormatsAudio: "Подржан формат je mp3",
    lblMaxFileSizeImage:"Максимална величина слике је 5MB",
    lblMaxSizeDocument: "Максимална величина документа је 5MB",
    lblMaxSizeVideo: "Максимална величина видеа је 50MB",
    lblCopyTraining: "Копирај тренинг",
    
    lblCalendar: "Календар",
    lblPreviousM: "Претходни",
    lblNextM: "Следећи",
    lblToday: "Данас",
    lblAddEvent: "Додај догађај",
    lblEventTitle: "Наслов догађаја",
    lblDate: "Датум",
    lblEditEvent: "Измени догађај",
    lblEventEdites: "Успешно сте изменили догађај!",
    lblEventDeleted: "Успешно сте обрисали догађај!",
    lblEventAdded: "Успешно сте додали догађај!",
    lblDelEvent: "Обриши догађај",
    lblDeleteThisEvent: "Да ли сте сигурни да желите да обришете догађај?",

    //zoom
    lblMeeting: "Zoom састанци",
    lblAddMeeting: "Додај састанак",
    lblMeetingTitle: "Наслов састанка",
    lblEditMeeting: "Измени састанак",
    lblMeetingEdites: "Успешно сте изменили састанак!",
    lblMeetingDeleted: "Успешно сте обрисали састанак!",
    lblMeetingAdded: "Успешно сте додали састанак!",
    lblDelMeeting: "Обриши састанак",
    lblDeleteThisMeeting: "Да ли сте сигурни да желите да обришете састанак?",
    lblMeetingId: "ID састанка",
    lblTime: "Време",
    lblInMinutes: "у минутима",
    lblHours: "Сат",
    lblMinutes: "Минут",
    lblUserUnavailable: "Корисник је заузет",
    lblCalling: "зове...",
    lblEvaluate: "Оцени",
    lblGrade: "Оцена",
    lblStart: "Започни",
    lblCantStartMeeting: "Још увек не можете започети састанак."
  }

}