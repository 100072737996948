import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface AssignData{
  id?:string
  testId?:string
  pretestId?:string
  surveyId?:string
  dateCreated:Date
}

@Component({
  selector: 'app-assign-test',
  templateUrl: './assign-test.component.html',
  styleUrls: ['./assign-test.component.scss']
})
export class AssignTestComponent implements OnInit {
  dataAssign:AssignData
  @ViewChild('assignForm') assignForm:NgForm
  tests;
  preTests;
  surveys;
  pretestId
  testId
  surveyId
  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AssignTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.fetchTests()
    this.fetchPretests()
    this.fetchSurveys()
    this.getLang()
    this.pretestId = this.data.pretestId
    this.testId = this.data.testId
    this.surveyId = this.data.surveyId
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  onSubmit(){
    let data:AssignData={
      id:this.data.id,
      dateCreated: new Date()
    }

  

      data.testId=this.assignForm.value.test
      data.pretestId=this.assignForm.value.pretest
      data.surveyId=this.assignForm.value.survey
  

    this.dashboardService.assignTests(data).subscribe(response=>{
      console.log(response)
      this.snackBar.open(this.labels.lblTestAssigned, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
    })
  }

  clearSurveySelection(event: MouseEvent) {
    event.preventDefault()
     event.stopPropagation();
    this.assignForm.form.get('survey').setValue("");
  }
  
  clearPreTestSelection(event: MouseEvent) {
    event.preventDefault()
     event.stopPropagation();
    this.assignForm.form.get('pretest').setValue("");
  }
  
  clearTestSelection(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation();
    this.assignForm.form.get('test').setValue("");
  }
  onNoClick(){
    this.dialogRef.close()
  }

  fetchTests(){
    this.dashboardService.fetchTestsByType(1).subscribe(response=>this.tests=response)
  }
  fetchPretests(){
    this.dashboardService.fetchTestsByType(2).subscribe(response=>this.preTests=response)
  }
  fetchSurveys(){
    this.dashboardService.fetchTestsByType(3).subscribe(response=>this.surveys=response)
  }

}