import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { Training } from 'src/app/models/training.model';
import { User } from 'src/app/models/user.model';
import { UserXTrainings } from 'src/app/models/userXTrainings.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserBeforeSurveyMessageComponent } from '../../survey/user-before-survey-message/user-before-survey-message.component';
import { UserDashboardService } from '../../user-dashboard.service';
import { UserBeforeTestMessageComponent } from '../../user-training/user-before-test-message/user-before-test-message.component';

@Component({
  selector: 'app-user-test-message',
  templateUrl: './user-test-message.component.html',
  styleUrls: ['./user-test-message.component.scss']
})
export class UserTestMessageComponent implements OnInit {

  test: Test
  testSub: Subscription

  labels;
  labelsSub: Subscription;

  lang;
  langSub: Subscription;

  training$: Observable<Training>
  training: Training
  trainingSub: Subscription

  user$: Observable<User>
  user: User
  userSub: Subscription

  userXTraining$: Observable<UserXTrainings>
  userXTraining: UserXTrainings
  userXTrainingSub: Subscription

  button1
  button2
  color

  survey$: Observable<Test>
  surveySub: Subscription
  survey

  btnTryAgain: boolean = false
  btnGoToTraining: boolean = true
  btnContinue: boolean = false
  test$: Observable<Test>
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<UserBeforeTestMessageComponent>,
    private userDashboardService: UserDashboardService,
    private authService: AuthService,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, private userLanguageService: UserLanguageService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.survey$ = this.userDashboardService.getSurveyForTraining(this.localStorageService.getItem('surveyId')).pipe(share())
    this.surveySub = this.userDashboardService.survey$.subscribe((test: Test) => {
      console.log("SURVEY FROM TEST: ")
      this.survey = test;
      console.log(this.test)
  });
  
    console.log(this.data)
    this.langSub = this.userLanguageService.lang$.subscribe((lang) =>{
      this.lang = lang;
    })
    this.training$ = this.userDashboardService.getTraining().pipe(share());

    this.trainingSub = this.userDashboardService.training$.subscribe((training) => {
      this.training = training;
    })

   
    this.testSub = this.userDashboardService.test$.subscribe((test) => {
      this.test = test;
      setTimeout(() => {
        this.updateTestInfo(this.test)
      },300)
    })

    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.userSub = this.authService.user$.subscribe((user) => {
      this.user = user
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.button1 = "buttonEMS"
          this.button2 = "buttonEMS1"
          this.color = "#c32a2a"

        }else if(this.user.companyId == 2){
          this.button1 = "buttonECG"
          this.button2 = "buttonECG1"
          this.color = "rgb(241,106,100)"
        }
        else{
          this.button1 = "buttonECG"
          this.button2 = "buttonECG1"
          this.color = "rgb(241,106,100)"
        }
      }, 200);
    })



  this.userDashboardService.getUserXTrainings(this.localStorageService.getItem('trainingId')).subscribe((usrXTr: UserXTrainings) => {
        setTimeout(() => {
          this.userXTraining = usrXTr;
          console.log("user x training")
          console.log(this.userXTraining)
        }, 500);
      })
  
  }

  updateTestInfo(test){
    if(test.required){
      if(this.data.passedTest){
        this.btnContinue = true
        this.btnGoToTraining = false
        this.btnTryAgain = false
      }else{
        console.log("REQUIRED I FAILED")
        if(Number(this.data.numOfTestDrops) == Number(test.numberOfTestDrops)){
          this.userDashboardService.updateVideoScoresForTraining({trainingId: this.data.trainingId, testId: test.id}).subscribe((response) => {
            console.log("UNIT::: ", response)
            this.localStorageService.setItem('currentUnit', response)
          })
          this.btnGoToTraining = true
          this.btnTryAgain = false
        }else{
          console.log("NIJE POSLEDNJI PUT")
          this.btnGoToTraining = true
          this.btnTryAgain = true
        }
      }
    }else{
      this.btnContinue = true
      this.btnGoToTraining = false
      this.btnTryAgain = false
    }
    console.log('da li prukazuje pokusaj ponovo', this.btnTryAgain)
  }

  goToTrainingPage(){
    this.dialogRef.close()
    this.router.navigateByUrl("/user/training-details")
    setTimeout(() => {
      window.location.reload()
    }, 250)
  }

  goToSurvey() {
    this.dialogRef.close()
    this.localStorageService.setItem('currentQuestionIndex', 0)
    this.localStorageService.setItem('questionScores', [])
    this.localStorageService.setItem('minutesLeft', this.test.durationTime)
    this.localStorageService.setItem('secondsLeft',0)
    //this.localStorageService.removeItem('test')
    this.router.navigateByUrl('user/start-survey')
    // this.openTestDialog()
  }

  goToTestPage() {
    
    this.localStorageService.setItem('currentQuestionIndex', 0)
    this.localStorageService.setItem('questionScores', [])
    this.localStorageService.setItem('test', null)
    this.localStorageService.setItem('minutesLeft', this.test.durationTime)
    this.localStorageService.setItem('secondsLeft',0)
    this.dialogRef.close()
    this.test$ = this.userDashboardService.getTestForTraining(this.training.testId).pipe(share())
    this.router.navigateByUrl('user/start-test');

    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  goToHomePage() {
    this.localStorageService.setItem('currentQuestionIndex', 0)
    this.localStorageService.setItem('questionScores', [])
    this.localStorageService.setItem('test', null)
    this.dialogRef.close()
    this.router.navigateByUrl('user/dashboard')
    setTimeout(() => {
      window.location.reload()
    }, 350)
  }
  

  // openTestDialog(): void {
  //   const dialogRef = this.dialog.open(UserBeforeSurveyMessageComponent, {
  //     disableClose: true,
  //     width: '350px',
  //   });

  // }

  downloadTestResults(trId): void {
    this.userDashboardService.downloadTestResults(trId, this.lang, this.user.userName);
  }


}
